import { NgModule } from "@angular/core";
 
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../shared/guard/auth-guard";
import { CoursePlayerComponent } from "../Components/course-player/course-player.component";
import { CourseComponent } from "../Components/course/course.component";
import { QuizComponent } from "../Components/quiz/quiz.component";
import { CourseDetailsComponent } from "./course-details/course-details.component";
import { SearchComponent } from "./search/search.component";
import { StudentProfileComponent } from "./student-profile/student-profile.component";
import { TopicComponent } from "./topic/topic.component";

const routes: Routes = [
  { path: 'StudentProfile', component: StudentProfileComponent, canActivate: [AuthGuard] },
  { path: 'Search', component: SearchComponent },
  { path: 'Topic', component: TopicComponent },
  { path: 'CourseDetails', component: CourseDetailsComponent },
  { path: 'Course', component: CourseComponent },
  { path: 'CoursePlayer', component: CoursePlayerComponent },
  { path: 'Quiz', component: QuizComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class AccademyRoutingModule {

}
