import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { LanguageService } from '../../../shared/services/Language.service';
import { UserLogin, UserDto, User, IUser } from '../../../shared/core/system-api';

import { Subscription } from 'rxjs';
import { SocialUser } from "../../../shared/models/social-user";
import { SocialAuthService } from "../../../shared/socialmedia/socialauth.service";
import { FacebookLoginProvider } from "../../../shared/socialmedia/providers/facebook-login-provider";
import { GoogleLoginProvider } from "../../../shared/socialmedia/providers/google-login-provider";
import { UserType } from '../../../shared/enums/enums.enum';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public loading = false;
  public submitted = false;
  public WrongUser = false;
  public InActiveUser = false;
  public returnUrl: string;
  public returnUrlForAdmin: string;
  currentLang: string;
  public Dublicate = false;
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

 // private user: IUser = null;
  GoogleLoginProvider = GoogleLoginProvider;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private languageService: LanguageService,
    private authService: SocialAuthService, 

  ) {
    const currentLangSubscription = this.languageService.currentLang.subscribe(x => this.currentLang = x);
    this.unsubscribe.push(currentLangSubscription);

  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = '';
    this.returnUrlForAdmin = 'AdminSite';

  }


  // convenience getter for easy access to form fields
  public get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.loading = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.loading = false;
      return;
    }

    var user = new UserLogin();
    user.email = this.f.username.value;
    user.password = this.f.password.value;
    const loginSubscription = this.authenticationService.login(user)
      .pipe(first())
      .subscribe(
        data => {
          if (data) {
            if (!data.isActive) {
              this.InActiveUser = true;
              this.WrongUser = false;
              this.loading = false;
            }
            else {
              this.languageService.switchLanguage(this.currentLang);
              if (data.type == UserType.Admin)
                this.router.navigate([this.returnUrlForAdmin]);
              else
                this.router.navigate([this.returnUrl]);
              this.WrongUser = false;
              this.InActiveUser = false;
            }
          }
          else {
            this.WrongUser = true;
            this.InActiveUser = false;
            this.loading = false;
          }
        },
        error => {
          // this.alertService.error("There is something went wrong, Please check with the IT Department.");
          alert("There is something went wrong, Please check with the IT Department.");
          this.loading = false;
        });

    this.unsubscribe.push(loginSubscription);
  }

  register(user,Type)  {
    var _user = new UserDto();
    _user.userName = user.name;
    _user.email = user.email;
    _user.password = user.id;
    _user.allowSendEmail = true;
    _user.isSocialLogin = true;
    _user.socialLoginType = Type;

    const loginSubscription = this.authenticationService.socailRegister(_user)
      .subscribe(
        data => {
          if (data) {
            if (data.type == UserType.Admin)
              this.router.navigate([this.returnUrlForAdmin]);
            else
              this.router.navigate([this.returnUrl]);
          }
          else {
            this.Dublicate = true;
            this.loading = false;
          }
        },
        error => {
          // this.alertService.error("There is something went wrong, Please check with the IT Department.");
          alert("There is something went wrong, Please check with the IT Department.");
          this.loading = false;
        });

    this.unsubscribe.push(loginSubscription);
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then( Logeduser => {
      this.register(Logeduser,2);
    });
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(Logeduser => {
      this.register(Logeduser,1);
    });;
  }

  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }

  signOut(): void {
    this.authService.signOut();
  }


}
