import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { CorporateService, UserExamService } from '../../../shared/core/system-api';
import { ExcelService } from '../../../shared/services/excel.service';

@Component({
  selector: 'app-admin-coprate-grid',
  templateUrl: './admin-coprate-grid.component.html',
  styleUrls: ['./admin-coprate-grid.component.scss']
})
export class AdminCoprateGridComponent implements OnInit   {
  displayedColumns: string[] = ['name', 'groupName', 'quizResults', 'complete', 'quizResultAfter'];
  private unsubscribe: Subscription[] = [];
  corporate: any;
  dataSource:any;
  constructor(public translate: TranslateService, private route: ActivatedRoute,
    private corporateService: CorporateService, public excelService: ExcelService, private userExamService: UserExamService) {
  }

  @Input() traineeList: any[];
  @Input('corprateId') corprateId: any;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.traineeList);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }


  clearFinalRecord(userCourseId) {
    Swal.fire({
      title: this.translate.instant('DEFAULT.confirmationTitle'),
      text: this.translate.instant('Corporate.ResetResults'),
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('DEFAULT.yes'),
      cancelButtonText: this.translate.instant('DEFAULT.no')
    }).then((result) => {
      if (result.value) {
        const changeUserStatus = this.userExamService.resetFinalResult(userCourseId, false).subscribe(data => {
          this.draw(this.corprateId);
        },
          err => {
            console.log(err);
          })
        this.unsubscribe.push(changeUserStatus);
      }
    });


  }

  draw(corporateID) {
    // console.log ("the data :---", this.dataSource)

    const getCorporateDetails = this.corporateService.getCorporateDetails(corporateID).subscribe(

      data => {
        if (data.success) {
          this.corporate = data.data;
          
        }
      });
    this.unsubscribe.push(getCorporateDetails);
  }

  applyFilter(event: Event ) {
    // debugger;
 

    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  //  this.filteredList = dataSource.filteredData as unknown as any[];
  }

}


