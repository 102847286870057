import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CourseService } from '../../../../shared/services/course.service';
import { SwiperOptions } from 'swiper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-new-courses',
  templateUrl: './new-courses.component.html',
  styleUrls: ['./new-courses.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewCoursesComponent implements OnInit {
  items: any[] = [];
  public config: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    slidesPerView: 4,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: false,
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
      }
    }
  };
  constructor(private courseService: CourseService) {
  }

  ngOnInit(): void {
    this.courseService.getCoursesList(10).subscribe(result => {

      if (result.success) {
        this.items = result.data;
      }
    });
  }

}
