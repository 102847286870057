//import { Component, OnInit, ViewChild, Input } from '@angular/core';
//@Component({
//  selector: 'app-mediaplayer',
//  templateUrl: './mediaplayer.component.html',
//  styleUrls: ['./mediaplayer.component.scss'],
//})
//export class MediaplayerComponent implements OnInit {
//  constructor() { }
//  @ViewChild('videoplayer') videoElement;
//  @Input() currentVideo;

//  video: HTMLVideoElement;
//  progressBarStyle = {
//    flexBasis: '0%',
//  };
//  iconClass = 'k-i-play';
//  mouseDown = false;

//  togglePlay() {
//    const method = this.video.paused ? 'play' : 'pause';
//    this.video[method]();
//    setTimeout(() => this.updateButton(), 10);
//  }

//  updateButton() {
//    const icon = this.video.paused ? 'k-i-play' : 'k-i-pause';
//    this.iconClass = icon;
//  }

//  handleVolumeChange(e) {
//    const { target } = e;
//    const { value } = target;
//    this.video.volume = value;
//  }

//  handleProgress() {
//    const percent = (this.video.currentTime / this.video.duration) * 100;
//    this.progressBarStyle.flexBasis = `${percent}%`;
//  }

//  seek(e: MouseEvent) {
//    const { srcElement: progress, offsetX } = e;
//    const  offsetWidth  = progress;
//    const seekTime = (offsetX / 100) * this.video.duration;
//    this.video.currentTime = seekTime;
//  }

//  ngOnInit() {
//    const { nativeElement } = this.videoElement;
//    this.video = nativeElement;
//  }

//  ngOnChanges(changes) {
//    if (this.video) {
//      this.progressBarStyle.flexBasis = '0%';
//      this.togglePlay();
//    }
//  }
//}

import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import videojs from 'video.js';

@Component({
  selector: 'app-mediaplayer',
   templateUrl: './mediaplayer.component.html',
  styleUrls: ['./mediaplayer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VjsPlayerComponent implements OnInit, OnDestroy {
  @ViewChild('target') target: ElementRef;
  // see options: https://github.com/videojs/video.js/blob/maintutorial-options.html
  @Input() options: {
    fluid: boolean,
    aspectRatio: string,
    autoplay: boolean,
    sources: {
      src: string,
      type: string,
    },
  };
  player: videojs.Player;

  constructor(
    private elementRef: ElementRef,
  ) { }

  ngOnInit() {
    this.instantiateVideo();
  }
  ngOnChanges() {
    this.player.src(this.options.sources);
  }
  instantiateVideo() {
    this.player = videojs(this.target.nativeElement, this.options, function onPlayerReady() { });
  }
  ngOnDestroy() {
    // destroy player
    if (this.player) {
      this.player.dispose();
    }
  }
}
