import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Corporate } from '../../../shared/core/system-api';
import { CorporateService } from '../../../shared/services/corporate.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-corperate',
  templateUrl: './corperate.component.html',
  styleUrls: ['./corperate.component.scss']
})

export class CorperateComponent implements OnInit {
  corporates: any[] = [];
  selectedCorporate: Corporate;
  private unsubscribe: Subscription[] = [];
  constructor(private router: Router, private corporateService: CorporateService, public translate: TranslateService, private toastr: ToastrService) { }

  ngOnInit() {
    this.draw();
  }
  
  draw() {
    const getCorporatesList = this.corporateService.getCorporatesList().subscribe(
      data => {
        if (data && data.data) {
          this.corporates = data.data;
        }
      });
    this.unsubscribe.push(getCorporatesList);
  }
  edit(c) {
    this.selectedCorporate = c;
    this.AddNewCorporate = true;
  }
  delete(id) {
    Swal.fire({
      title: this.translate.instant('DEFAULT.confirmationTitle'),
      text: this.translate.instant('Corporate.confirmationCorporateDelete'),
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('DEFAULT.yes'),
      cancelButtonText: this.translate.instant('DEFAULT.no')
    }).then((result) => {
      if (result.value) {
        const changeUserStatus = this.corporateService.changeCorporateStatus(id, false).subscribe(
          data => {
            if (data.success) {
              this.toastr.success(this.translate.instant('Corporate.CorporateDeleted'));
              this.corporates = this.corporates.filter(x => x.id != id);
            }
          });
        this.unsubscribe.push(changeUserStatus);
      }
    });
  }

  details(id) {
    this.router.navigate(['AdminSite/Corporate/Details', { corporateID: id }]);
  }
  AddNewCorporate = false;

  ToggleAddNewCorporate() {
    this.selectedCorporate = null;
    this.AddNewCorporate = !this.AddNewCorporate;
    if (this.AddNewCorporate == false) {
      this.draw();
    }
  }
  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }
}
