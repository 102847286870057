import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SWALMSGSService } from '../../../shared/services/swal-msgs.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../shared/services/user.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { LanguageService } from '../../../shared/services/Language.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  public UserEditForm: FormGroup;
  match: boolean;
  submit: boolean;
  fullEdit: boolean;
  wrongOldPassword: boolean;
  public loading = false;
  currentlang: string;
  constructor(
    private SWAL: SWALMSGSService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private notificationService: NotificationService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private languageService: LanguageService,
    private toastr: ToastrService
  ) {
    const currentLangSubscription = this.languageService.currentLang.subscribe(res => { this.currentlang = res; });
    this.dialogRef.disableClose = true;
    this.match = true;

    this.UserEditForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d$@$!%*?&].{6,}')]],
      confirmPass: ['', Validators.required]
    });
    //if (this.data.userId) {
    //}
  }
  ngOnInit() {

  }

  // convenience getter for easy access to form fields
  public get f() { return this.UserEditForm.controls; }

  onSubmit() {
    this.loading = true;
    
    this.submit = true;
    this.wrongOldPassword = false;
    if (this.UserEditForm.invalid) {
      this.loading = false;
      return;
    }
    if (!this.match) {
      this.loading = false;
      return
    }
    let oldPassword = this.f.oldPassword.value;
    let newPassword = this.f.newPassword.value;
    this.userService.changePassword(oldPassword, newPassword).subscribe(res => {
      if (res) {
        if (res.success) {
          if (this.currentlang == "us")
            this.toastr.success("Password Changed Successfully");
          else
            this.toastr.success("تم تغيير الرقم السري بنجاح");
            this.authenticationService.logout();
            this.dialogRef.close();
            this.router.navigate(['/SignIn']);
            this.notificationService.closeConnection();
        }
        else {
          this.wrongOldPassword = true;
        }
      }
      this.loading = false;
    }, err => {
      if (this.currentlang == "us")
        this.toastr.error("Something went wrong. Please contact the IT department.");
      else
        this.toastr.error("حدث خطأ ما. يرجى التواصل مع قسم تقنية المعلومات");
        this.loading = false;
    });
  }

  CloseDia() {
    this.dialogRef.close();

  }

  public checkValidationsForConfrimPassword() {
    let newPassword = this.f.newPassword.value;
    let confirmPassword = this.f.confirmPass.value;

    this.match = newPassword === confirmPassword;
  }
}
