import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { CourseService as RemoteService, Result, Filter, Course, FileParameter } from '../core/system-api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

export const API_BASE_URL = new InjectionToken<string>('API_BASE_URL');

@Injectable({
  providedIn: 'root'
})
export class CourseService {
  private baseUrl: string;

  constructor(private CourseService: RemoteService, private httpClient: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string)
  {
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "https://localhost:5002";
  }

  page: number = 1;
  pagesize: number = 5;
  filter: Filter[] = [];
  Coursedto: Course = null;

  get(Id: number | undefined): Observable<Course | null> {
    return this.CourseService.get(Id).pipe(map(res => {
      if (res && res.success) {
        return <Course>res.data;
      }
    }));
  }

  getAll(dataSource: any): Observable<Result | null> {
    return this.CourseService.getAll(dataSource.page, dataSource.pageSize, dataSource.filter)
      .pipe(map(res => {
        if (res && res.success) {
          return res;
        }
      }));
  }
  
  getCoursesList(Id: number = 0): Observable<Result | null> { return this.CourseService.getCoursesList(Id) }


  changeCourseStatus(ID, Status): Observable<Result | null> {
    return this.CourseService.changeCourseStatus(ID, Status).pipe(map(res => {
      if (res)
        return res;
    }));
  }

  create(userDto): Observable<Course | null> {
    return this.CourseService.create(userDto)
      .pipe(map(res => {
        if (res && res.success)
          return <Course>res.data;
      }));
  }

  update(userDto): Observable<Course | null> {
    return this.CourseService.update(userDto)
      .pipe(map(res => {
        if (res && res.success)
          return <Course>res.data;
      }));
  }

  upload(file: FormData, folderName: string) {
    let url_ = this.baseUrl + "/api/Course/Upload?";
    if (folderName !== undefined && folderName !== null)
      url_ += "folderName=" + encodeURIComponent("" + folderName) + "&";
    url_ = url_.replace(/[?&]$/, "");

    return this.httpClient.post<any>(url_, file, {
      reportProgress: true,
      observe: 'events'
    });  
  }

  search(Text: string): Observable<Result | null> { return this.CourseService.search(Text) }
}
