import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Course } from '../../../shared/core/system-api';
import { LanguageService } from '../../../shared/services/Language.service';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit {
  private unsubscribe: Subscription[] = []; 

  currentlang: string;
  @Input() item: Course;
  @Input() hideprice: boolean;
  constructor(private router: Router, private languageService: LanguageService) {
    const currentLangSubscription = this.languageService.currentLang.subscribe(res => { this.currentlang = res; });
    this.unsubscribe.push(currentLangSubscription);
  }

  ngOnInit() {
  }

  openCourseDetails(id) {
    this.router.navigate(['CourseDetails', { courseID: id }]);
  }
}
