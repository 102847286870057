import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CategoryService } from '../../../../shared/services/category.service';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Router } from '@angular/router';
import { LanguageService } from '../../../../shared/services/Language.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-recent-programs',
  templateUrl: './recent-programs.component.html',
  styleUrls: ['./recent-programs.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class RecentProgramsComponent implements OnInit {
  items: any[] = [];
  public config: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    slidesPerView: 3,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: true,
    pagination: false
  };

  currentlang: string;
  private unsubscribe: Subscription[] = [];
  constructor(private router: Router, private categoryService: CategoryService, private languageService: LanguageService) {
    const currentLangSubscription = this.languageService.currentLang.subscribe(res => { this.currentlang = res; });
    this.unsubscribe.push(currentLangSubscription);
  }

  ngOnInit(): void {
    this.categoryService.getCategorysList(5).subscribe(result => {
      
      if (result.success) {
        this.items = result.data;
      }
    });
  }
  getCategoryCourses(ID) {
    this.router.navigate(['Topic', { categoryID: ID }]);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }
}
