import { API_BASE_URL } from './shared/core/system-api';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TopicsComponent } from './pages/Academy/landing/topics/topics.component';
import { NewCoursesComponent } from './pages/Academy/landing/new-courses/new-courses.component';
import { BenefitsComponent } from './pages/Academy/landing/benefits/benefits.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { RegisterComponent } from './pages/auth/register/register.component';
import { HeaderComponent } from './pages/Components/header/header.component';
import { FooterComponent } from './pages/Components/footer/footer.component';
import { LandingComponent } from './pages/Academy/landing/landing.component';
import { IntroSliderComponent } from './pages/Academy/landing/intro-slider/intro-slider.component';
import { RecentProgramsComponent } from './pages/Academy/landing/recent-programs/recent-programs.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule, MatDialogModule, MatPaginatorModule } from '@angular/material';
import { ToastrModule } from 'ngx-toastr';
import { ChangePasswordComponent } from './pages/auth/change-password/change-password.component';
import { SocialAuthServiceConfig } from './shared/socialmedia/socialauth.service';
import { SocialLoginModule } from './shared/socialmedia/sociallogin.module';
import { FacebookLoginProvider } from './shared/socialmedia/providers/facebook-login-provider';
import { GoogleLoginProvider } from './shared/socialmedia/providers/google-login-provider';

import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ForgetPasswordComponent } from './pages/auth/forget-password/forget-password.component';
import { CourseDetailsComponent } from './pages/Academy/course-details/course-details.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { JwtModule } from '@auth0/angular-jwt';
import { ErrorInterceptor } from './shared/helpers/error-interceptor';
import { JwtInterceptor } from './shared/helpers/jwt-interceptor';
import { StudentProfileComponent } from './pages/Academy/student-profile/student-profile.component';
import { SearchComponent } from './pages/Academy/search/search.component';
import { TopicComponent } from './pages/Academy/topic/topic.component';
import { ResetPasswordComponent } from './pages/auth/ResetPassword/ResetPassword.component';
import { CourseComponent } from './pages/Components/course/course.component';
import { VjsPlayerComponent } from './pages/Components/mediaplayer/mediaplayer.component';
import { CoursePlayerComponent } from './pages/Components/course-player/course-player.component';
import { environment } from '../environments/environment';
import { CreateCourseComponent } from './pages/AdminSite/create-course/create-course.component';
import { AdminNavigationComponent } from './pages/Components/admin-navigation/admin-navigation.component';
import { AdminHeaderComponent } from './pages/Components/admin-header/admin-header.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatBadgeModule } from '@angular/material/badge';
import { DashboardComponent } from './pages/AdminSite/dashboard/dashboard.component';
import { CorperateComponent } from './pages/AdminSite/corperate/corperate.component';
import { AdminHomeComponent } from './pages/AdminSite/admin-home/admin-home.component';
import { UsersComponent } from './pages/AdminSite/users/users.component';
import { CreateNewComponent } from './pages/AdminSite/corperate/create-new/create-new.component';
import { DetailsComponent } from './pages/AdminSite/corperate/details/details.component';
import { QuizComponent } from './pages/Components/quiz/quiz.component';
import { MatRadioModule } from '@angular/material/radio';
import { LoaderInterceptor } from './shared/helpers/loader-interceptor';
import { MyLoaderComponent } from './pages/Components/loader/loader.component';
import { LoaderService } from './shared/services/loader.service';
import { AccademyRoutingModule } from './pages/Academy/accademy.routing.module';
import { AdminRoutingModule } from './pages/AdminSite/admin.routing.module';
import { AuthrizeRoutingModule } from './pages/auth/authrize.routing.module';
import {MatSortModule} from '@angular/material/sort';
import { AdminCoprateGridComponent } from './pages/Components/admin-coprate-grid/admin-coprate-grid.component';
import {CoursesListComponent} from './pages/AdminSite/create-course/courses-list/courses-list.component';
import {EditCourseComponent} from './pages/AdminSite/edit-course/edit-course.component';

export function jwtTokenGetter() {
  return localStorage.getItem('token');
}

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
  declarations: [
    MyLoaderComponent,
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LandingComponent,
    IntroSliderComponent,
    RecentProgramsComponent,
    TopicsComponent,
    NewCoursesComponent,
    BenefitsComponent,
    LoginComponent,
    RegisterComponent,
    ChangePasswordComponent,
    ForgetPasswordComponent,
    CourseDetailsComponent,
    StudentProfileComponent,
    SearchComponent,
    TopicComponent,
    CourseComponent,
    TopicComponent,
    ResetPasswordComponent,
    CreateCourseComponent,
    AdminNavigationComponent,
    AdminHeaderComponent,
    VjsPlayerComponent,
    CoursePlayerComponent,
    DashboardComponent,
    CorperateComponent,
    AdminHomeComponent,
    UsersComponent,
    CreateNewComponent,
    DetailsComponent,
    QuizComponent,
    AdminCoprateGridComponent,
    CoursesListComponent,
    EditCourseComponent
  ],
  imports: [
    MatSortModule,
    MatPaginatorModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    TranslateModule.forRoot(),
    ToastrModule.forRoot(),
    SocialLoginModule,
    SwiperModule,
    MatButtonModule,
    MatTooltipModule,
    SocialLoginModule,
    MatMenuModule,
    MatStepperModule,
    MatSelectModule,
    MatCardModule,
    MatTabsModule,
    MatTableModule,
    MatExpansionModule,
    MatBadgeModule,
    MatRadioModule,
    AccademyRoutingModule,
    AdminRoutingModule,
    AuthrizeRoutingModule,

    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter
      }
    })

  ],
  // exports:[

  // ],
  entryComponents: [
    ChangePasswordComponent
  ],
  providers: [{
    provide: 'SocialAuthServiceConfig',
    useValue: {
      autoLogin: false,
      providers: [
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider(
            '1056082598540-vdugfa5gaiitplpkfn29haplvgsodd07.apps.googleusercontent.com'
          )
        },
        {
          id: FacebookLoginProvider.PROVIDER_ID,
          provider: new FacebookLoginProvider('1219303065152429')
        }
      ]
    } as SocialAuthServiceConfig,
  },
  {
    provide: API_BASE_URL,
    useValue: environment.API_BASE_URL
  },
  { provide: SWIPER_CONFIG, useValue: DEFAULT_SWIPER_CONFIG },
  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    LoaderService,
  { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
