import { Injectable } from '@angular/core';
import Swal from 'sweetalert2'
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SWALMSGSService {

  constructor(public translate: TranslateService) { }

  SavedSusessMsg(dialogRef, MSG = this.translate.instant('CRUD.datasaved')) {
    Swal.fire({
      title: this.translate.instant('CRUD.done'),
      text: MSG,
      type: 'success',
      showCancelButton: false,
      confirmButtonText: this.translate.instant('CRUD.OK'),
      focusConfirm: false,
      showCloseButton: true,
    }).then((result) => {
      if (result.value || result.dismiss) {
        dialogRef.close(true);//passing true to close func to return true after success to afterclose func in ALL the list-comps.ts
      }
    });
  }


  Info(MSG) {
    Swal.fire({
      title: "Info",
      text: MSG,
      type: 'info',
      showCancelButton: false,
      focusConfirm: false,
      showCloseButton: true,
      confirmButtonText: this.translate.instant('CRUD.OK'),
    });
  }

  Warning(MSG) {
    Swal.fire({
      title: "Warning",
      text: MSG,
      type: 'warning',
      showCancelButton: false,
      focusConfirm: false,
      showCloseButton: true,
      confirmButtonText: this.translate.instant('CRUD.OK'),
    });
  }

  ERROR(MSG = this.translate.instant('CRUD.ERROR'), type: any = 'error') {
    Swal.fire({
      title: this.translate.instant('CRUD.eror'),
      text: MSG,
      type: type,
      showCancelButton: false,
      focusConfirm: false,
      showCloseButton: true,
      confirmButtonText: this.translate.instant('CRUD.OK'),
    });
  }

  SusessMsg(MSG) {
    Swal.fire({
      title: this.translate.instant('CRUD.done'),
      text: MSG,
      type: 'success',
      showCancelButton: false,
      confirmButtonText: this.translate.instant('CRUD.OK'),
      focusConfirm: false,
      showCloseButton: true,
    }).then((result) => {
    });
  }







}
