import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { CorporateService as RemoteService, Result, Filter, Corporate, FileParameter } from '../core/system-api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

export const API_BASE_URL = new InjectionToken<string>('API_BASE_URL');

@Injectable({
  providedIn: 'root'
})
export class CorporateService {
  private baseUrl: string;

  constructor(private CorporateService: RemoteService, private httpClient: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string)
  {
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "https://localhost:5002";
  }

  page: number = 1;
  pagesize: number = 5;
  filter: Filter[] = [];
  Corporatedto: Corporate = null;

  get(Id: number | undefined): Observable<Result | null> {
    return this.CorporateService.get(Id).pipe(map(res => {
      if (res && res.success) {
        return res;
      }
    }));
  }

  getAll(dataSource: any): Observable<Result | null> {
    return this.CorporateService.getAll(dataSource.page, dataSource.pageSize, dataSource.filter)
      .pipe(map(res => {
        if (res && res.success) {
          return res;
        }
      }));
  }
  getCorporatesList(Id: number = 0): Observable<Result | null> { return this.CorporateService.getCorporatesList(Id) }
  getCorporateDetails(Id: number = 0): Observable<Result | null> { return this.CorporateService.getCorporateDetails(Id) }


  changeCorporateStatus(ID, Status): Observable<Result | null> {
    return this.CorporateService.changeCorporateStatus(ID, Status).pipe(map(res => {
      if (res)
        return res;
    }));
  }

  create(userDto): Observable<Result | null> {
    return this.CorporateService.create(userDto)
      .pipe(map(res => {
        if (res)
          return res;
      }));
  }

  update(userDto): Observable<Result | null> {
    return this.CorporateService.update(userDto)
      .pipe(map(res => {
        if (res)
          return res;
      }));
  }

}
