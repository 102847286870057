export const locale = {
  lang: 'sa',
  data: {
    dateLang: 'ar',
    Validation: {
      Required: "هذا الحقل مطلوب.",
      Email: "لقد أدخلت بريدًا إلكترونيًا غير صالح.",
      ConflictDate: "يجب أن يكون تاريخ الانتهاء أكبر من تاريخ البدء.",
    },
    DEFAULT: {
      Placeholder: "اكتب هنا...",
      Loading: "تحميل...",
      yes: "نعم",
      no: "لا",
      confirmationTitle: 'هل أنت متأكد؟',
      null: 'غير موجود',
    },
    HEADER: {
      Logout: "خروج",
      Language: "اللغة",
      DIR: "rtl",
      Notification: "الإشعارات",
      ChangePassword: "تغيير كلمه السر",
      MyCourses: "كورساتي",
      AdminSite: "موقع المسؤول",
      AboutUs: "نبذة عنا",
      JoinOurAcademy: "انضم إلى أكاديميتنا",
      SiteMap: "خريطة الموقع",
      ContactUs: "اتصل بنا",
      SearchOnCourse: "بحث في الدورة التدريبية",
      SignIn: "تسجيل الدخول",
      SignUp: "تسجيل",
    },
    FOOTER: {
      WELCOME: "أهلا بك...! أكاديمية NI - منصة التعليم",
      NIAEP: "أكاديمية NI - منصة التعليم [NIAEP]",
      Text: "يقدّر كل طفل ، ويقدر الفرصة لمساعدة الطلاب على التعلم والنمو. كن جزءًا من مدارسنا ، سواء كنت أحد الوالدين أو طالبًا أو متطوعًا مجتمعيًا أو شريكًا.",
      NEWSLETTERSIGNUP: "الاشتراك في الرسائل الإخبارية",
      ReceiveTheLatest: "احصل على أحدث المعلومات المفيدة يوميًا.",
      EmailAddress: "أدخل عنوان بريدك الالكتروني",
      SignUp: "تسجيل",
      FeelFreeContactUs: "لا تتردد في الاتصال بنا:",
      Info: "+20 2 2480 07 03 / 04 | info@niconsulting.com.eg",
      Home: "الصفحة الرئيسية",
      AboutUs: "نبذة عنا",
      JoinOurAcademy: "انضم إلى أكاديميتنا",
      Support: "مساعدة و دعم",
      TermsAndConditions: "الأحكام والشروط",
      PrivacyPolicy: "سياسة خاصة",
      SiteMap: "خريطة الموقع",
      ContactUs: "اتصل بنا",
      CopyRight: "أكاديمية NI © سياسة الخصوصية 2021",
    },
    RESETPASS: {
      ResetPassword: "إعادة تعيين كلمة المرور",
      PleaseNewPassowrd: "الرجاء إدخال كلمة المرور الجديدة",
      NewPassowrd: "أدخل كلمة المرور الجديدة",
      NewPassowrdReq: "كلمة المرور الجديدة مطلوبة",
      NewPassowrdPtrn: "6 أحرف على الأقل: أحرف صغيرة وأحرف كبيرة وأرقام",
      ReEnterNewPassowrd: "تأكيد كلمة المرور الجديدة",
      ReEnterNewPassowrdReq: "تأكيد كلمة المرور مطلوب",
      DontMatch: "كلمة المرور وتأكيد كلمة المرور غير متطابقين",
      ChangePasswordBTN: "تغيير كلمة المرور",
    },
    REGISTER: {
      SignUpTo: "اشترك في",
      StartLearning: "ابدأ التعلم!",
      EmailRegistered: "تم تسجيل هذا البريد الإلكتروني بالفعل",
      FirstName: "الاسم الأول",
      FirstNameReq: "الاسم الأول مطلوب",
      LastName: "اسم العائلة",
      LastNameReq: "اسم العائلة مطلوب",
      Email: "البريد الإلكتروني",
      EmailReq: "البريد الإلكتروني مطلوب",
      EmailPtrn: "هذا البريد الإلكتروني غير صالح",
      Password: "كلمة المرور",
      NewPassowrdReq: "كلمة المرور مطلوبة",
      NewPassowrdPtrn: "6 أحرف على الأقل: أحرف صغيرة وأحرف كبيرة وأرقام",
      allowsendemail: "نعم! أرغب في تحقيق أقصى استفادة من أكاديمية NI من خلال تلقي رسائل بريد إلكتروني تحتوي على صفقات حصرية وتوصيات شخصية ونصائح تعليمية!",
      SignUp: "تسجيل",
      Agree: "بالتسجيل ، فإنك توافق على",
      TermsOfUse: "شروط الاستخدام",
      And: "و",
      PrivacyPolicy: "سياسة الخصوصية",
      AlreadyHaveAnAccount: "لديك حساب بالفعل",
    },
    LOGIN: {
      LoginTo: "تسجيل الدخول إلى",
      NIAcademyAccount: "حساب أكاديمية NI",
      Error1: "لا يسمح للمستخدم باستخدام هذا النظام",
      Error2: "الرجاء التحقق من اسم المستخدم وكلمة المرور مرة أخرى!",
      Email: "البريد الإلكتروني",
      EmailReq: "البريد الإلكتروني مطلوب",
      Password: "كلمة المرور",
      PassowrdPtrn: "6 أحرف على الأقل: أحرف صغيرة وأحرف كبيرة وأرقام",
      PassowrdReq: "كلمة المرور مطلوبة",
      Login: "دخول",
      ForgotPassword: "نسيت كلمة المرور",
      CreateNewAccount: "إنشاء حساب جديد",
      Facebook: "استخدم حسابك على Facebook",
      Gmail: "استخدم حسابك على Gmail",
    },
    ForgetPass: {
      ForgotPassword: "نسيت كلمة المرور",
      PleaseRegisteredEmail: "الرجاء إدخال بريدك الإلكتروني المسجل",
      Error1: "هذا المستخدم لم يعد قابلاً للاستخدام.",
      Error2: "البريد الإلكتروني غير موجود.",
      Error3: "خطأ Mailer حاول في وقت لاحق",
      Success: "تم الإرسال ، يرجى التحقق من بريدك الإلكتروني",
      Email: "البريد الإلكتروني",
      EmailReq1: "البريد الإلكتروني مطلوب",
      EmailReq2: "هذا البريد الإلكتروني غير صالح",
      ResetPassword: "إعادة تعيين كلمة المرور",
      AlreadyHaveAnAccount: "لديك حساب بالفعل",
      SignIn: "تسجيل الدخول",
    },
    Error: {
      Message: "ليس لديك امتيازات ، يرجى الاتصال بمسؤول أكاديمية NI.",
      Home: "العودة إلى الصفحة الرئيسية",
    },
    ChangePass: {
      ChangePassword: "تغيير كلمة المرور",
      OldPassword: "كلمة المرور القديمة",
      OldPasswordReq: "كلمة المرور القديمة مطلوبة",
      WrongPassword: "كلمة مرور خاطئة",
      NewPassword: "كلمة مرور جديدة",
      NewPasswordReq: "مطلوب كلمة مرور جديدة",
      NewPasswordPtrn: "6 أحرف على الأقل: أحرف صغيرة وأحرف كبيرة وأرقام",
      ConfirmPassword: "تأكيد كلمة المرور",
      ConfirmPasswordReq1: "تأكيد كلمة المرور مطلوب",
      ConfirmPasswordReq2: "تأكيد كلمة المرور لا تتطابق مع كلمة المرور الجديدة",
      Save: "حفظ",
    },
    CourseDetails: {
      BuyNow: "شراء الان",
      CreatedBy: "أنشاء بـ :",
      Content: "محتويـات الكورس",
    },
    Benefits: {
      Title: "البرامج الحديثة",
      TitleBody: "تحرص أكاديمية إن آي للاستشارات على تقديم كل ما هو جديد ومفيد للمتدرب فيما يثري قدراته العلمية ومهاراته المهنية في كافة المجالات.",
      NIABenefits: "مزايا أكاديمية إن آي للاستشارات",
      GradesAssignments: "إمكانية التدريب في أي وقت مناسب للمتدرب",
      GradesAssignmentsBody: "يستطيع المتدرب مشاهدة البرامج التدريبية في أي وقت على مدار اليوم.",
      AttendanceBehavior: "منهجية Education 2.0 العالمية",
      AttendanceBehaviorBody: "يتعلق نجاح وفعالية التدريب على رغبة وحرص المتدرب على التعلم والمشاركة الفعالة والمشاركة الفعالة في التدريب.",
      DistrictStateTests: "تقييم نسبة التطور المعرفي",
      DistrictStateTestsBody: "يتمكن المتدرب من متابعة نسبة التطور المعرفي الخاصة به من خلال نسب التقييم قبل وبعد حضور الدورة كاملة.",
      ACADEMICRESOURCES: "التواصل المباشر مع المدرب",
      ACADEMICRESOURCESBody: "يحضر المتدرب محاضرة تفاعلية مع المدرب يتمكن خلالها من إلقاء أي أسئلة أو مناقشة أي موضوعات تتعلق بالدورة التدريبية التي شاهدها قبل موعد هذه المحاضرة.",
      ScheduleCourses: "التنوع والتكامل",
      ScheduleCoursesBody: "تعطي أكاديمية إن آي للاستشارات الإمكانية للمتدرب لاختيار برامج تدريبية متعددة سواء في مجالات متنوعة تثري النطاق المعرفي للمتدرب أو اختيار برامج بمستويات أعلى في ذات الموضوع لتتكامل في بناء قدرات تخصصية أشمل للمتدرب.",
      AfterSchoolSupport: "التحديث المعرفي",
      AfterSchoolSupportBody: "يتمكن المتدرب من إعادة مشاهدة الدورة التدريبية لأي عدد من المرات خلال عام كامل لاستخدام الدورة التدريبية والموارد التعليمية كمرجع حين الاحتياج.",
    },
    NewCourses: {
      NewCourses: "دورات جديدة",
    },
    RecentPrograms: {
      RecentPrograms: "البرامج الحديثة",
      Body: "أكاديمية NI تقدم تعليم مونتيسوري عالي الجودة ومعتمد من AMI للأطفال في المرحلة الابتدائية والابتدائية والأطفال الصغار في المنطقة.",
    },
    Topics: {
      TopicsRecommended: "مواضيع مقترحة",
    },
    Course: {
      buynow: "شراء الان",
      EGP: "جنيه",
      completed: "مكتمل",
      NotTaken: "لم يتم أخذ الاختبار النهائي بعد",
      FinalScore: "نتيجة الامتحان"
    },
    Search: {
      NotFound: "عفوا لا توجد نتيجه للبحث",
      Back: "العوده للصفحه الرئيسيه",
      Explore: "تصفح",
      Courses: "الكورسات",
      results: "نتيجه لـ ",
      Createdby: "أنشاء بواسطه",
    },
    Profile: {
      MyCourses: "كورسـاتى",
      MyInfo: "معلوماتى",
      FirstName: "الاسـم الاول",
      LastName: "الاسـم الاخير",
      Email: "الايميل"
    },
    Corporate: {
      CorporateManagement: "إدارة الشركة",
      AddNewCorporate: "إضافة شركة جديدة",
      ContractNo: "رقم العقد",
      ContractName: "اسم العقد",
      ContactPerson: "جهة الاتصال",
      NoOfCourses: "عدد الدورات",
      NoOfGroups:"عدد المجموعات",
      NoOfTrainees: "عدد المتدربين",

      CorporateInformation: "معلومات الشركة",
      CreateNewCorporate: "إنشاء شركة جديدة",
      BackToCorporateList: "العودة إلى قائمة الشركات",
      Name: "الاسم",
      NamePH: "على سبيل المثال: NI Consulting",
      HeadOfficeAddress: "عنوان المكتب الرئيسي",
      HeadOfficeAddressPH: "على سبيل المثال: مبنى مكاتب ستار كابيتال A2 ، الطابق التاسع ، مجمع سيتي ستار للأعمال والتجارة 2 ، شارع على راشد ، مدينة نصر ، القاهرة ، مصر",
      LocationOnGoogleMaps: "الموقع على خرائط جوجل",
      LocationOnGoogleMapsPH: "على سبيل المثال: 30.07454134025531 ، 31.346966728836392",
      SaveCorporate: "حفظ الشركة",
      Return: "العودة",

      Contracts: "العقود",
      BasicInfromation: "المعلومات الأساسية",
      Number: "الرقم",
      NumberPH: "على سبيل المثال: 20210428",
      StartDate: "تاريخ البدء",
      StartDatePH: "على سبيل المثال: 01/01/2021",
      EndDate: "تاريخ الانتهاء",
      EndDatePH: "على سبيل المثال: 31/01/2021",
      BIName: "الاسم",
      BINamePH: "على سبيل المثال: اسم العقد",
      Next: "التالي",
      ContactPersonInfromation: "معلومات جهة الاتصال",
      CPIName: "الاسم",
      CPINamePH: "على سبيل المثال: الاسم الكامل",
      JobTitle: "المسمى الوظيفي",
      JobTitlePH: "على سبيل المثال: المدير التنفيذي",
      MobileNumber: "رقم الهاتف المحمول",
      MobileNumberPH: "على سبيل المثال: +20 XXX X XXX XXX",
      EmailAddress: "عنوان البريد الإلكتروني",
      EmailAddressPH: "على سبيل المثال: email@domain.com",
      Previous: "السابق",
      Course: "دورة",
      SelectContractCourses: "اختر دورات العقد",
      SaveContract: "حفظ العقد",
      SaveAndContinue: "حفظ ومتابعة",

      TraineeList: "قائمة المتدربين",
      TraineeName: "اسم المتدرب",
      TraineeNamePH: "على سبيل المثال: الاسم الكامل",
      TraineeEntity: "كيان متدرب",
      TraineeEntityPH: "على سبيل المثال: وزارة التخطيط",
      SelectContractNumber: "حدد رقم العقد",
      AssignedCourses: "الدورات المعينة",
      GroupName: "أسم المجموعة",
      GroupNumber: "رقم المجموعة",
      Groups: "المجموعات",
      AddGroup: "أضافة المجموعة",
      EditGroup: "تحديث المجموعة",
      SaveTrainee: "حفظ المتدرب",
      Cancel: "إلغاء",

      confirmationContractDelete: 'سوف تقوم بحذف هذا العقد',
      confirmationTraineeDelete: 'سوف تقوم بحذف هذا المتدرب',
      confirmationGroupDelete: 'سوف تقوم بحذف هذا المجموعة',
      confirmationCorporateDelete: 'سوف تقوم بحذف هذه الشركة',
      CorporateCreated: 'تم إنشاء الشركة بنجاح',
      CorporateUpdated: 'تم تحديث الشركة بنجاح',
      CorporateDeleted: 'تم حذف الشركة بنجاح',
      ContractCreated: 'تم إنشاء العقد بنجاح',
      ContractUpdated: 'تم تحديث العقد بنجاح',
      ContractDeleted: 'تم حذف العقد بنجاح',
      TraineeCreated: 'تم إنشاء المتدرب بنجاح',
      TraineeUpdated: 'تم تحديث المتدرب بنجاح',
      TraineeDeleted: 'تم حذف المتدرب بنجاح',

      NotUniqueCorporate: 'هذه الشركة موجودة بالفعل',
      NotUniqueContract: 'هذا العقد موجود بالفعل',
      NotUniqueTrainee: 'هذا المتدرب موجود بالفعل',

      ContractHavDependance: 'هذا العقد له تبعية احذفها أولاً',
      TraineeHavDependance: 'هذا المتدرب لديه تبعية احذفهم أولاً',
      ExportToExcel: 'تصدير إلى Excel',
      QuizResults: 'نتائج الاختبار',
      Before: 'قبل',
      After: 'بعد',
      Complete: 'مكتمل',
      ResetResults:'الغاء نتيجه الامتحان النهائى'
    },
    CreateCourse: {
      CourseInformation: 'معلومات عن الكورس',
      CreateCourse: 'أنشاء كورس',
      CourseVedios: 'فيديوهات الكورس',
      Price: 'السعر',
      CourseNameEnglish: 'أسم الكورس بالانجليزى',
      CourseDetialsEnglish: 'تفاصيل الكورس بالانجليزى',
      CourseNameArabic: 'أسم باللغه العربيه',
      CourseDetailsArabic: 'تفاصيل الكورس باللغه العربيه',
      UploadCourse: 'تحميل صوره الكورس',
      ChooseCategory: 'فئه الكورس',
      ChooseFile: 'اختر الملف',
      SaveandContinue: 'حفظ و استمرار',
      Cancel: 'الغـاء',
      SaveandFinish: 'حفظ وانهاء',
      BankofQuestions: 'بنك الاسئله',
      VideoName: 'أسـم الفيديو بالغه الانجليزيه',
      VideoArName: 'أسـم الفيديو بالغه العربيه',
      VideoThumbnail: 'صوره الفيديو',
      Add: 'أضافـه',
      NameEn: 'الاسم باللغه الانجليزيه',
      NameAr: 'الاسم باللغه العربيه',
      Thumbnail: 'غلاف الصوره',
      Path: 'المسار',
      TheQuestion: 'السـوال',
      VideoLink: 'أدخل لينك الفيديو ',
      Answer01:'الاجابه 1',
      Answer02:'الاجابه 2',
      Answer03:'الاجابه 3',
      Answer04: 'الاجابه 4',
      RightAnswer: 'الأجابه الصحيحه',
      ConfirmAnswer:'هل تريد الحفظ و المتابعه'
    }
  },

};
