import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CourseVides, UserDto } from '../../../shared/core/system-api';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { LanguageService } from '../../../shared/services/Language.service';
import { UserCourseService } from '../../../shared/services/userCourse.service';
import { VideoService } from '../../../shared/services/video.service';

@Component({
  selector: 'app-course-player',
  templateUrl: './course-player.component.html',
  styleUrls: ['./course-player.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class CoursePlayerComponent implements OnInit {
  
  currentVideo: CourseVides;
  isUserCourse: boolean = false;
  sources: string = '';
  courseId: number = 0;
  playlist:CourseVides[] = [
    //{
    //  name: 'Arial view of roads',
    //  source:
    //    "http://niaccdemy-001-site1.itempurl.com/vedios/videogular.mp4",
    //  thumbnail:
    //    'https://res.cloudinary.com/hackafro/image/upload/c_scale,h_100,w_150/v1554641467/Screenshot_2019-04-07_at_1.39.17_PM_purcgf.png',
    //},
    //{
    //  name: 'Blur colorful lights',
    //  source:
    //    'https://player.vimeo.com/external/305211631.sd.mp4?s=3d46306a3d07d1c56eb64f1fcb1ba96232e34d90&profile_id=164&oauth2_token_id=57447761',
    //  thumbnail:
    //    'https://res.cloudinary.com/hackafro/image/upload/c_scale,h_100,w_150/v1554641309/Screenshot_2019-04-07_at_1.46.12_PM_ztnroy.png',
    //},
    //{
    //  name: 'Amazing view of the sunset',
    //  source:
    //    'https://player.vimeo.com/external/306619138.sd.mp4?s=a7cb8a56ee700da618a4bc6bdd474eca0cf75d92&profile_id=164&oauth2_token_id=57447761',
    //  thumbnail:
    //    'https://res.cloudinary.com/hackafro/image/upload/c_scale,h_100,w_150/v1554641380/Screenshot_2019-04-07_at_1.46.38_PM_f6nyr4.png',
    //},
    //{
    //  name: 'Lighthouse by the sea',
    //  source:
    //    'https://player.vimeo.com/external/312662160.sd.mp4?s=22154e69be5722a528e3c1cc374250af726a2b44&profile_id=164&oauth2_token_id=57447761',
    //  thumbnail:
    //    'https://res.cloudinary.com/hackafro/image/upload/c_scale,h_100,w_150/v1554641395/Screenshot_2019-04-07_at_1.46.26_PM_xgbfdq.png',
    //},
  ];
  currentUser: UserDto;
  currentlang = '';
  private unsubscribe: Subscription[] = []; 

  constructor(private router: Router, private videoService: VideoService, private route: ActivatedRoute, private authenticationService: AuthenticationService, private userCourseService: UserCourseService,
    private languageService: LanguageService) {
    const currentUserSubscription = this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    const currentLangSubscription = this.languageService.currentLang.subscribe(res => { this.currentlang = res; });
    this.unsubscribe.push(currentLangSubscription);
    this.unsubscribe.push(currentUserSubscription);
  }

  ngOnInit() {
    this.courseId = Number(this.route.snapshot.queryParamMap.get('courseID'));
    let vedioId = Number(this.route.snapshot.queryParamMap.get('vedioID'));

    if (this.currentUser) {
      this.userCourseService.isUserCourse(this.courseId).subscribe(result => {
        if (result)
          this.isUserCourse = true;
        else
          this.router.navigateByUrl('');
      });
    }
    else {
      this.router.navigateByUrl('');
    }
    this.userCourseService.addUserCourseVideo(vedioId, this.courseId).subscribe();
    this.videoService.getVideosPerCourse(this.courseId).subscribe(result => {
      this.playlist = result.data;
      this.currentVideo = this.playlist.find(x => x.vedioId == vedioId);
      this.sources = this.currentVideo.vedioPath;
    });
   
  }
  onVideoClick(video) {
    this.currentVideo = video;
    this.sources = video.vedioPath;
    this.userCourseService.addUserCourseVideo(video.vedioId, video.courseId).subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }


}
