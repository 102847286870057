import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CourseVides, UserDto } from '../../../shared/core/system-api';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { CourseService } from '../../../shared/services/course.service';
import { CourseExamsService } from '../../../shared/services/courseExams.service';
import { LanguageService } from '../../../shared/services/Language.service';
import { UserCourseService } from '../../../shared/services/userCourse.service';
import { VideoService } from '../../../shared/services/video.service';

declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-course-details',
  templateUrl: './course-details.component.html',
  styleUrls: ['./course-details.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class CourseDetailsComponent implements OnInit {
  item: any = [];
  isUserCourse: boolean = false;
  vedios: CourseVides[] = [];
  courseId: number = 0;
  hasRegistered: boolean = false;
  currentUser:  UserDto;
  currentlang = '';
  userCourseId: number;
  private unsubscribe: Subscription[] = [];
  hideTakenBefor: boolean = false;
  hideTakenAfter: boolean = false;
  courseAttachments: Attachment[] = [];
  showTab: boolean = false;
  watchedVedios = '';
  watchedPercentage: number=0.0;

  constructor(private router: Router, private route: ActivatedRoute, private courseService: CourseService, private userCourseService: UserCourseService,
    private videoService: VideoService, private authenticationService: AuthenticationService, private courseExamService: CourseExamsService,
    private languageService: LanguageService) {
    const currentUserSubscription = this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    const currentLangSubscription = this.languageService.currentLang.subscribe(res => { this.currentlang = res; });
    this.unsubscribe.push(currentLangSubscription);
    this.unsubscribe.push(currentUserSubscription);
  }

  ngOnInit() {

    this.route.paramMap.subscribe(params => {
      this.courseId = Number(params.get('courseID'));

      if (this.currentUser) {
        
        this.userCourseService.isUserCourse(this.courseId).subscribe(result => {
          if (result) {
            this.isUserCourse = true;
            this.userCourseId = result.id;
            this.watchedVedios = result.watchedVideos;
         

          this.courseExamService.get(result.id).subscribe(data => {
            if (data) {
              if (data.examTakenBefore) {
                this.hideTakenBefor = true;
              }
              if (data.examTakenAfter) {
                this.hideTakenAfter = true;
              }
            }
           
          });
 }
        });
       
      }
      this.courseService.get(this.courseId).subscribe(result => {
        if (this.courseId == 1) {
          this.showTab = true;
          this.courseAttachments = [
            { name: "ملخص مقدمة دراسات الجدوى", path: "/Attachments/مقدمة دراسات الجدوى الاساسى ف 1-2-3-4.pdf" },
            { name: "ملخص دراسة الجدوى القومية", path: "/Attachments/دراسة الجدوى القومية المستوى الاساسى ف 5-6-7.pdf" },
            { name: "ملخص دراسة الجدوى المالية", path: "/Attachments/دراسة الجدوى المالية المستوى الاساسى ف 8-9-10-11-12.pdf" }
          ];
        } else if (this.courseId == 22) {
          this.showTab = true;
          this.courseAttachments = [
            { name: "ملخص التعريف بفكرة المشروع ودراسة الجدوى التسويقية", path: "/Attachments/ملخص التعريف بفكرة المشروع ودراسة الجدوى التسويقية 1.pdf" },
            { name: "ملخص دراسة الجدوى التسويقية 2", path: "/Attachments/ملخص دراسة الجدوى التسويقية 2.pdf" },
            { name: "ملخص دراسة الجدوى القومية النهائية", path: "/Attachments/ملخص دراسة الجدوى القومية النهائية.pdf" },
            { name: "ملخص دراسة الجدوى البيئية", path: "/Attachments/ملخص دراسة الجدوى البيئية.pdf" },
            { name: "ملخص دراسة الجدوى التنظيمية", path: "/Attachments/ملخص دراسة الجدوى التنظيمية.pdf" },
            { name: "ملخص دراسة الجدوي المالية", path: "/Attachments/ملخص دراسة الجدوي المالية.pdf" },
            { name: "ملخص دراسات الجدوي الفنية والهندسية", path: "/Attachments/ملخص دراسات الجدوي الفنية والهندسية.pdf" },
            { name: "ملخص كيفية إتخاذ القرار النهائي بشأن المشروعات المعروضة للدراسة", path: "/Attachments/ملخص كيفية إتخاذ القرار النهائي بشأن المشروعات المعروضة للدراسة.pdf" }
          ];
        }
        else if (this.courseId == 27) {
          this.showTab = true;
          this.courseAttachments = [
            { name: "الدليل_الجديد_للتخطيط_الإستراتيجى.pdf", path: "/Attachments/الدليل_الجديد_للتخطيط_الإستراتيجى.pdf" },
            { name: "برنامج عمل الحكومة التفصيلي.pdf", path: "/Attachments/برنامج عمل الحكومة التفصيلي.pdf" },
            { name: "ملخص برنامج عمل الحكومة-مضغوط.pdf", path: "/Attachments/ملخص برنامج عمل الحكومة-مضغوط.pdf" },
            { name: "last book.pdf", path: "/Attachments/last book.pdf" }
          ];
        }

        else if (this.courseId == 30) {
          this.showTab = true;
          this.courseAttachments = [
            { name: "ملخص- الحوكمة والمواصفة الدولية أيزو 37000.pdf", path: "/Attachments/ملخص- الحوكمة والمواصفة الدولية أيزو 37000.pdf" }
          ];
        }
        else if (this.courseId == 31) {
          this.showTab = true;
          this.courseAttachments = [
            { name: "ملخص ISO 9001 VER 2.pdf", path: "/Attachments/ملخص ISO 9001 VER 2.pdf" }
          ];
        }
        else if (this.courseId == 32) {
          this.showTab = true;
          this.courseAttachments = [
            { name: "ملخص نظام إدارة  الإبتكار56002-2019 Version 2.pdf", path: "/Attachments/ملخص نظام إدارة  الإبتكار56002-2019 Version 2.pdf" }
          ];
        }
        this.item = result;
      });

      this.videoService.getVideosPerCourse(this.courseId).subscribe(result => {
        this.vedios = result.data;
        var vedioCount = this.vedios.length;
        var watchedCount = (this.watchedVedios.match(/,/g) || []).length;
        var percantge = (watchedCount + 1) / vedioCount;
        this.watchedPercentage = percantge;
        console.log(this.watchedPercentage);
      });
    });
    
  }



  CoursePlayer(courseId, vedioId) {
    if (this.currentUser && this.isUserCourse && this.hideTakenBefor)
    this.router.navigateByUrl('/CoursePlayer?courseID=' + courseId + '&vedioID=' + vedioId);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }

  NavigateToQuiz(status: string)
  {
    let start = 'false';
    if (status == 'start') {
      start = 'true';
    }
    this.router.navigateByUrl('/Quiz?id=' + this.courseId + '&UserCourse=' + this.userCourseId + '&IsStart=' + start);
  }

  downloadAttachment(name, path) {
    FileSaver.saveAs(path, name);
  }

 

}
class Attachment {
  name: string;
  path: string;
}
