import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UserDto } from '../../../shared/core/system-api';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { LanguageService } from '../../../shared/services/Language.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LandingComponent implements OnInit {

  private unsubscribe: Subscription[] = [];

  constructor() {
  }
  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }
}
