import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { UserExamService as RemoteService, Result, Filter, Course, FileParameter, UserCourseExams } from '../core/system-api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

export const API_BASE_URL = new InjectionToken<string>('API_BASE_URL');

@Injectable({
  providedIn: 'root'
})
export class CourseExamsService {
 

  constructor(private CourseExamService: RemoteService, private httpClient: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string)
  {
    
  }

  page: number = 1;
  pagesize: number = 5;
  filter: Filter[] = [];
  Examsedto: UserCourseExams = null;

  get(Id: number | undefined): Observable<UserCourseExams | null> {
    return this.CourseExamService.get(Id).pipe(map(res => {
      if (res && res.success) {
        return <UserCourseExams>res.data;
      }
    }));
  }

  getAll(dataSource: any): Observable<Result | null> {
    return this.CourseExamService.getAll(dataSource.page, dataSource.pageSize, dataSource.filter)
      .pipe(map(res => {
        if (res && res.success) {
          return res;
        }
      }));
  }
  getCoursesList(Id: number = 0): Observable<Result | null> {
    return this.CourseExamService.getUserCourseExamsList(Id)
      .pipe(map(res => {
      if (res && res.success) {
        return res.data;
      }
    }));
      }


 

  create(Examsedto): Observable<UserCourseExams | null> {
    return this.CourseExamService.create(Examsedto)
      .pipe(map(res => {
        if (res && res.success)
          return <UserCourseExams>res.data;
      }));
  }

  update(Examsedto): Observable<UserCourseExams | null> {
    return this.CourseExamService.update(Examsedto)
      .pipe(map(res => {
        if (res && res.success)
          return <UserCourseExams>res.data;
      }));
  }

  resetFinalResult(userId: number | undefined): Observable<UserCourseExams | null> {
    return this.CourseExamService.resetFinalResult(userId, false).pipe(map(res => {
      if (res && res.success) {
        return <UserCourseExams>res.data;
      }
    }));
  }

 
}
