import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/authentication.service';
import { AbilityService } from '../services/ability.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private ability: AbilityService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
      // authorised so return true
      if (!this.checkAbility(state.url)) {
        this.router.navigate(['/error']);
        return false;
      }
      return true;
    }

    // not logged in so redirect to login page with the return url
   // this.router.navigate([''], { queryParams: { returnUrl: state.url } });
    this.router.navigate(['./landing.component.html'], { queryParams: { returnUrl: state.url } });
    return false;
  }

  private checkAbility(url) {
    switch (url) {
      case "/":
        return true;
      case '/error':
        return true;
      default:
        return true;
    }
  }
}
