import { Injectable } from '@angular/core';
import { UserService as RemoteService, Result, Filter, UserDto } from '../core/system-api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private UserService: RemoteService) { }

  page: number = 1;
  pagesize: number = 5;
  filter: Filter[] = [];
  Userdto: UserDto = null;

  get(Id: number | undefined): Observable<UserDto | null> {
    return this.UserService.get(Id).pipe(map(res => {
      if (res && res.success) {
        return <UserDto>res.data;
      }
    }));
  }

  getAll(dataSource: any): Observable<Result | null> {
    return this.UserService.getAll(dataSource.page, dataSource.pageSize, dataSource.filter)
      .pipe(map(res => {
        if (res && res.success) {
          return res;
        }
      }));
  }
  getUsersList(): Observable<Result | null> { return this.UserService.getUsersList() }


  changeUserStatus(ID, Status, ContractID=0): Observable<Result | null> {
    return this.UserService.changeUserStatus(ID, Status, ContractID).pipe(map(res => {
      if (res)
        return res;
    }));
  }

  update(userDto): Observable<UserDto | null> {
    return this.UserService.update(userDto)
      .pipe(map(res => {
        if (res && res.success)
          return <UserDto>res.data;
      }));
  }
  changePassword(oldPassword, newPassword): Observable<Result | null > {
    return this.UserService.changePassword(oldPassword, newPassword)
      .pipe(map(res => {
        return res;
      }));
  }
  changeCurrentUserPassword(access_token: string | null | undefined, password: string | null | undefined): Observable<Result> {
    return this.UserService.changeCurrentUserPassword(access_token, password);
  }
  createTrainee(trainee): Observable<Result> {
    return this.UserService.createTrainee(trainee);
  }
  updateTrainee(trainee): Observable<Result> {
    return this.UserService.updateTrainee(trainee);
  }
}
