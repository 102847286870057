import { Component, Renderer2, ElementRef } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, NavigationCancel } from '@angular/router';

import { AuthenticationService } from './shared/services/authentication.service';
import { UserDto } from './shared/core/system-api';
import { LanguageService } from './shared/services/Language.service';

// language list
import { locale as enLang } from './shared/config/i18n/en';
import { locale as arLang } from './shared/config/i18n/ar';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
declare let $: any;

@Component({
  selector: 'app-root', templateUrl: 'app.component.html', providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ]
})
export class AppComponent {
  currentUser: UserDto;
  IsAdminSite: boolean = null;

  location: any;
  routerSubscription: any;
  languageChanged: boolean = false;

  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  currentLang: string;
  constructor(
    private el: ElementRef,
    private render: Renderer2,
    private router: Router,
    private authenticationService: AuthenticationService,
    private languageService: LanguageService,
    public translate: TranslateService
  ) {

    this.languageService.renderer = this.render;
    this.languageService.el = this.el;
    this.languageService.loadTranslations(enLang, arLang);
    const currentUserSubscription = this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
    });
    debugger;
    if (!this.languageChanged && localStorage.getItem('currentLanguage') != 'us') {
      localStorage.setItem('currentLanguage', 'sa');
      this.languageChanged = true;
    }
    const currentLangSubscription = this.languageService.currentLang.subscribe(x => this.currentLang = x);
    if (localStorage.getItem('currentLanguage') == '') {
      localStorage.setItem('currentLanguage', 'sa');
    }
    this.languageService.switchLanguage(localStorage.getItem('currentLanguage'));
    this.unsubscribe.push(currentUserSubscription);
    this.unsubscribe.push(currentLangSubscription);

  }
  /**
* On init
*/
  ngOnInit(): void {


    this.recallJsFuntions();
    const routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // hide splash screen
        //this.splashScreenService.hide();
        // scroll to top on every route change
        window.scrollTo(0, 0);
        // to display back the body content
        setTimeout(() => { }, 500);
      }
      let url = <string>(<any>event).url
      if (url) {
        if (url.includes("AdminSite"))
          this.IsAdminSite = true;
        else
          this.IsAdminSite = false;
      }
    });
    this.unsubscribe.push(routerSubscription);
  }


  recallJsFuntions() {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          $('.preloader').fadeIn('slow');
        }
      });
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
      .subscribe(event => {
        $.getScript('../assets/js/custom.js');
        $('.preloader').fadeOut('slow');
        this.location = this.router.url;
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0)
      });
  }

  /**
 * On Destroy
 */
  ngOnDestroy() {
    localStorage.getItem('currentLanguage') == 'sa'
    this.routerSubscription.unsubscribe();

    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }
}
