import { Injectable, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

// Tranlsation
import { TranslateService } from '@ngx-translate/core';
import { debug } from 'util';
import { forEach } from '@angular/router/src/utils/collection';
export interface Locale {
  lang: string;
  // tslint:disable-next-line:ban-types
  data: Object;
}

@Injectable({ providedIn: 'root' })
export class LanguageService {
  // Private properties

  private langIds: any = [];
  private currentLanguageSubject: BehaviorSubject<string>;
  public currentLang: Observable<string>;

  public renderer: Renderer2;

  public el: ElementRef;

  constructor(public translate: TranslateService) {
    var currentL = localStorage.getItem('currentLanguage');
    if (!currentL)
      localStorage.setItem('currentLanguage', 'us');
    this.currentLanguageSubject = new BehaviorSubject<string>(localStorage.getItem('currentLanguage'));
    this.currentLang = this.currentLanguageSubject.asObservable();
    // add new langIds to the list
    this.translate.addLangs(['en']);

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');
  }

  public get currentLanguageValue(): string {
    return this.currentLanguageSubject.value;
  }
  /**
* Load Translation
*
* @param args: Locale[]
*/
  loadTranslations(...args: Locale[]): void {
    const locales = [...args];

    locales.forEach(locale => {
      // use setTranslation() with the third argument set to true
      // to append translations instead of replacing them
      this.translate.setTranslation(locale.lang, locale.data, true);
      this.langIds.push(locale.lang);
    });

    // add new languages to the list
    this.translate.addLangs(this.langIds);
  }



  switchLanguage(code) {
    this.switch(code);

    // load default styles
    if (this.currentLanguageValue === "sa") {
      this.switchToRTL();
    }
    else {
      this.switchToLTR();
    }
  }

  switchToRTL() {
    var rtlElements = <HTMLLinkElement[]><any>document.getElementsByName("rtl");
    var ltrElements = <HTMLLinkElement[]><any>document.getElementsByName("ltr");
    Array.prototype.forEach.call(ltrElements, el => {
      el.disabled = true;
    });
    Array.prototype.forEach.call(rtlElements, el => {
      el.disabled = false;
    });
  }

  switchToLTR() {
    var rtlElements = <HTMLLinkElement[]><any>document.getElementsByName("rtl");
    var ltrElements = <HTMLLinkElement[]><any>document.getElementsByName("ltr");
    Array.prototype.forEach.call(rtlElements, el => {
      el.disabled = true;
    });
    Array.prototype.forEach.call(ltrElements, el => {
      el.disabled = false;
    });
  }
  switch(code: string) {
    this.translate.use(this.translate.getDefaultLang());
    this.translate.use(code);
    localStorage.setItem('currentLanguage', code);
    this.currentLanguageSubject.next(code);
  }
}
