import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserDto, AccountService, UserLogin, Result } from '../core/system-api';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    
  private currentUserSubject: BehaviorSubject<UserDto>;
  public currentUser: Observable<UserDto>;

  constructor(private accountService: AccountService) {
    this.currentUserSubject = new BehaviorSubject<UserDto>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): UserDto {
    return this.currentUserSubject.value;
  }

  login(user: UserLogin) {
    return this.accountService.authenticate(user)
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && (user.token || !user.isActive))
        {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        }
      }));
  }

  register(user: UserDto) {
    return this.accountService.register(user)
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && (user.token || !user.isActive)) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        }
      }));
  }

  socailRegister(user: UserDto) {
    return this.accountService.socialAuthenticate(user)
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && (user.token || !user.isActive)) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        }
      }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('oldTabs');
    this.currentUserSubject.next(null);
  }

  forgotPassword(email: string | null | undefined): Observable<Result> {
    return this.accountService.forgotPassword(email);
  }
}
