import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './pages/Academy/landing/landing.component';
import { CoursesListComponent } from './pages/AdminSite/create-course/courses-list/courses-list.component';


const routes: Routes = [
 
  { path: '', component: LandingComponent },
  // academy site
  { path: 'AdminSite/CoursesList', component: CoursesListComponent },
  
  // admin site


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
