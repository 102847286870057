import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Course, CourseVides } from '../../../shared/core/system-api';
import { CategoryService } from '../../../shared/services/category.service';
import { CourseService } from '../../../shared/services/course.service';
import { VideosService } from '../../../shared/core/system-api';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { SelectList, SelectOptions } from '../../../shared/models/SelectList.model';
import { CourseQuestionsService } from '../../../shared/services/courseQuestions.service';
import { DISABLED } from '@angular/forms/src/model';
import { element } from 'protractor';

@Component({
  selector: 'app-edit-course',
  templateUrl: './edit-course.component.html',
  styleUrls: ['./edit-course.component.scss']
})
export class EditCourseComponent implements OnInit {

  cats: any[] = [];
  vedios: any[] = [];
  questions: any[] = [];
  vedioAdd = false;
  questionAdded = false;
  catName = '';
  courses: FormGroup;
  courseVedios: FormGroup;
  courseQuestions: FormGroup;
  selectedAnswer: number = 0;
  public loading = false;
  public submitted = false;
  public vedioSubmitted = false;
  public questionSubmitted = false;


  courseId: number = 0;
  rowIndex = 0;

  folderPath = 'CourseImages/';
  imageName = '';
  vedioimageName = '';
  public progress: number;
  public message: string;
  @Output() public onUploadFinished = new EventEmitter();

  AnswersList: SelectOptions[] = [];

  Course_ID ;
  Course_Data;

  selectedCorporate;

    Video_array ;
  constructor(private router: Router, 
     private categoryService: CategoryService,
     private formBuilder: FormBuilder, 
     private questionServices: CourseQuestionsService,
     private courseService: CourseService, 
     private vedioService: VideosService, 
     private toastr: ToastrService, 
     private http: HttpClient,
     private activatedRoute: ActivatedRoute,
     ) { }

  ngOnInit() {
    
    console.log("query parameter from header",JSON.parse( this.activatedRoute.snapshot.queryParams['Course'] ) )
      
    this.Course_ID = JSON.parse( this.activatedRoute.snapshot.queryParams['Course'] );
    console.log ("The Course ID is :------" , this.Course_ID)

    this.courseId = this.Course_ID;

    this.courseService.get(this.Course_ID).subscribe((res:any)=>{
         this.Course_Data = res;
         console.log ("The Course Data :----", this.Course_Data)
         this.courses.patchValue({ name: this.Course_Data.name });
         this.courses.patchValue({ arname: this.Course_Data.nameAr });
         this.courses.patchValue({ price: this.Course_Data.price });
         this.courses.patchValue({ details: this.Course_Data.description });
         this.courses.patchValue({ ardetials: this.Course_Data.descriptionaR });
         this.courses.patchValue({ category: this.Course_Data.categoryId });

        //  this.courseVedios.patchValue({ vedioName: this.Course_Data.courseVides[0].name});
        //  this.courseVedios.patchValue({ vedioArName: this.Course_Data.courseVides[0].arName });
        //  this.courseVedios.patchValue({ vedioPath: this.Course_Data.courseVides[0].vedioPath});
 


          this.courseQuestions.patchValue({ question: this.Course_Data.courseQuestions[0].questionTitle });
          this.courseQuestions.patchValue({ answer1: this.Course_Data.courseQuestions[0].answerOne});
          this.courseQuestions.patchValue({ answer2: this.Course_Data.courseQuestions[0].answerTwo});
          this.courseQuestions.patchValue({ answer3: this.Course_Data.courseQuestions[0].answerThree});
          this.courseQuestions.patchValue({ answer4: this.Course_Data.courseQuestions[0].answerFour });
          this.courseQuestions.patchValue({ rightAnswer: this.Course_Data.courseQuestions[0].rightAnswer });


       this.vedios = this.Course_Data.courseVides
       this.Video_array = this.Course_Data.courseVides
       this.questions = this.Course_Data.courseQuestions


    })
     
    this.courses = this.formBuilder.group({
      category: ['', Validators.required],
      price: ['', Validators.required],
      name: ['', Validators.required],
      details: ['', Validators.required],
      arname: ['', Validators.required],
      ardetials: ['', Validators.required],
    });

    this.courseVedios = this.formBuilder.group({
      vedioName: ['', Validators.required],
      vedioArName: ['', Validators.required],
      vedioPath: ['', Validators.required],

    });

    this.courseQuestions = this.formBuilder.group({
      question: ['', Validators.required],
      answer1: ['', Validators.required],
      answer2: ['' , Validators.required],
      answer3: ['', Validators.required],
      answer4: ['', Validators.required],
      rightAnswer: ['', Validators.required],

    });
   
    this.categoryService.getCategorysList(0).subscribe(result => {
      if (result.success) {
        this.cats = result.data;
      }
    });

    this.setAnsewrsInputDisabled();

    // this.edit(this.vedios[0].id);
  }

  setCatName(catName) {
    this.catName = catName;
  }
 

  public get f() { return this.courses.controls; }
  public get v() { return this.courseVedios.controls; }
  public get q() { return this.courseQuestions.controls; }

 //#region Region Question Sections

  onSubmit(stepper: MatStepper) {
    this.submitted = true;
    this.loading = true;

    // stop here if form is invalid
    if (this.courses.invalid) {
      this.loading = false;
      return;
    }

    var course = new Course();
    course.categoryId = this.f.category.value;
    course.name = this.f.name.value;
    course.nameAr = this.f.arname.value;
    course.description = this.f.details.value;
    course.descriptionaR = this.f.ardetials.value;
    course.price = this.f.price.value;
    course.imagePath = this.folderPath+ this.imageName;
    course.id =  this.Course_ID;
    
    this.courseService.update(course).subscribe(
      data => {
        console.log("Update Course Api Res :-", data)

        if (data != null) {
          // this.courseId = data.id;
          this.Course_ID = data.id;
          this.toastr.success("the course is updated sucessfully");
          stepper.next();
        } 
        else
          this.toastr.error("The course name is already exist");
      }
      ,
      error => {
        alert("There is something went wrong, Please check with the IT Department.");
        this.loading = false;
      });

    // stepper.next();
    this.loading = false;
  }

 //#endregion
 
  addVedio() {
   
    if (this.courseId == 0) {
      this.toastr.warning("Add Course first");
      return;
    }

    this.vedioSubmitted = true;
    this.loading = true;

    // stop here if form is invalid
    if (this.courseVedios.invalid) {
      this.loading = false;
      return;
    }
    var imageBeforeAdd = this.vedioimageName;
    this.vedios.push({
      vedioId: 0, courseId: this.courseId, name: this.v.vedioName.value, arName: this.v.vedioArName.value,
      vedioPath: this.v.vedioPath.value, thubnails: 'VedioThubnils/' + this.vedioimageName
    });
    this.vedioAdd = true;
    this.vedioSubmitted = false;
    this.courseVedios.reset();
    this.vedioimageName = imageBeforeAdd;
  }

  submitCourseVedios(stepper: MatStepper) {
    if (this.vedios.length == 0)
    {
      this.toastr.warning("Add vedios first");
      return;
    }
    this.vedioService.createBulk(this.vedios).subscribe(
      data => {
        if (data!= null) {
          this.vedioAdd = false;
          this.vedios = [];
          this.toastr.success("the vedios is added");

         //stepper.reset();
          stepper.next();
        }
     
      });
  }

  cancleVedio(stepper: MatStepper)
  {
    stepper.reset();
    stepper.previous();
  }

  public uploadFile(files: FileList, folderName: string) {
    this.imageName = '';
    if (files.length === 0) {
      return;
    }

    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload);

    this.courseService.upload(formData,folderName)
      .subscribe(
        event => {
          if (folderName =='CourseImages')  
            this.imageName = fileToUpload.name;
          else
            this.vedioimageName = fileToUpload.name;
        
          if (event.type === HttpEventType.UploadProgress)
            this.progress = Math.round(100 * event.loaded / event.total);
          else if (event.type === HttpEventType.Response) {
            this.message = 'Upload success.';
            this.onUploadFinished.emit(event.body);
          };
        });
  }
 
  //#region Region Question Sections

  setAnsewrsInputDisabled() {
    this.q.answer2.disable();
    this.q.answer3.disable();
    this.q.answer4.disable();
  }

  fillAnswerList(value, index) {
    if (value != null && index < 4 ) {
     
      switch (index +1) {
        case 1:
          this.q.answer2.enable();
          break;
        case 2:
          this.q.answer3.enable();
          break;
        case 3:
          this.q.answer4.enable();
          break;
      }
      
    }
    var answer = value.target.value;

    var answerObject = new SelectOptions();
    answerObject.Key = answer;
    answerObject.Value = index + 1;

    if (this.AnswersList[index] != null) {

      this.AnswersList.splice(index, 1, answerObject);
    }
    else {

      this.AnswersList.splice(index, 0, answerObject);
    }

  }

  setSelectedAnswer(value) {
    this.selectedAnswer = value;
  }

  addQuestion() {
    if (this.courseId == 0) {
      this.toastr.warning("Add Course first");
      return;
    }
   // debugger;
    if (this.AnswersList.length != 4) {
      this.toastr.info("please add the four answers");
      return;
    }
    if (this.selectedAnswer == 0) {
      this.toastr.info("please select the rigt answer");
      return;
    }

    this.questionSubmitted = true;
    this.loading = true;

    if (this.courseQuestions.invalid) {
      this.loading = false;
      this.toastr.info("All fields are mandatory");
      return;
    }
    //this.courseId = 1;
    this.questions.push({
      questionId: 0, courseId: this.courseId, questionTitle: this.q.question.value, answerOne: this.q.answer1.value,
      answerTwo: this.q.answer2.value, answerThree: this.q.answer3.value, answerFour: this.q.answer4.value, rightAnswer: (this.q.rightAnswer.value*1)
    });
    this.questionAdded = true;
    this.AnswersList = [];
    this.setAnsewrsInputDisabled();
   this.courseQuestions.reset();
  }

  deleteQuestion(index)
  {
    this.questions.splice(index, 1);
  }

  submitCourseQuestions(stepper: MatStepper) {
    //debugger;
    this.questionServices.createBulk(this.questions).subscribe(
      data => {
        this.questionAdded = false;
        this.questions = [];
        this.AnswersList = [];
        if (data != null) {
          this.toastr.success("the questions is added");
        } else
          this.toastr.info("this course already have questions");
       
         
        stepper.reset();
        stepper.previous();
      },
      err => {
      });
  }

 //#endregion Region 

 edit(video) {
        // this.courseVedios.patchValue({ vedioName: video.name});
        // this.courseVedios.patchValue({ vedioArName: video.arName });
        // this.courseVedios.patchValue({ vedioPath: video.vedioPath});
        // console.log ("The Edit Api :-----",this.courseVedios.value )
        this.courseVedios.controls['vedioName'].setValue(video.name)
        this.courseVedios.controls['vedioArName'].setValue(video.arName)
        this.courseVedios.controls['vedioPath'].setValue(video.vedioPath)

        console.log ("The Edit course video :-----",this.courseVedios.value )


 }

 editquestion( Question){
   
  this.courseQuestions.controls['question'].setValue(Question.questionTitle)
  this.courseQuestions.controls['answer1'].setValue(Question.answerOne)
  this.courseQuestions.controls['answer2'].setValue(Question.answerTwo)
  this.courseQuestions.controls['answer3'].setValue(Question.answerThree)
  this.courseQuestions.controls['answer4'].setValue(Question.answerFour)
  this.courseQuestions.controls['rightAnswer'].setValue(Question.rightAnswer)

  console.log ("The Edit Question  :-----",this.courseQuestions.value )

 }

 

}
