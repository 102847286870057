import { Inject, Injectable, InjectionToken, Optional, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import * as signalR from "@aspnet/signalr";
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Notification, NotificationService as RemoteService, Result } from '../core/system-api';
import { AuthenticationService } from './authentication.service';
//import { NotificationComponent } from '../components/header/notification/notification.component';
export const API_BASE_URL = new InjectionToken<string>('API_BASE_URL');

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  
  private baseUrl: string;
  private currentNotificationSubject: BehaviorSubject<Notification>;
  public currentNotification: Observable<Notification>;
    stoppedManual: boolean;

  constructor(
    private NotificationService: RemoteService,
    private authenticationService: AuthenticationService,
    private CFR: ComponentFactoryResolver,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    
    this.baseUrl = baseUrl ? baseUrl : "https://localhost:5002";
    this.currentNotificationSubject = new BehaviorSubject<Notification>(null);
    this.currentNotification = this.currentNotificationSubject.asObservable();
  }
  private hubConnection: signalR.HubConnection

  public startConnection() {
    if (!this.authenticationService.currentUserValue) return;

    this.stoppedManual = false;


    var url = this.baseUrl + '/notify';

    Object.defineProperty(WebSocket, 'OPEN', { value: 1, });

    const options: signalR.IHttpConnectionOptions = {
      accessTokenFactory: () => {
        return this.authenticationService.currentUserValue.token;
      }
    };

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(url, options)
      .build();

    this.broadcastNotification();

    this.hubConnection
      .start()
      .then(() => { })
      .catch(err => { });


    //this.hubConnection.onclose(async () => { await this.RestartConnection(); });

  }
  closeConnection() {
    this.currentNotificationSubject.next(null);

    this.stoppedManual = true;
    if (this.hubConnection)
    this.hubConnection.stop()
      .then(() => { })
      .catch(err => { });
  }
  broadcastNotification() {
    this.hubConnection.on('broadcastnotification', (data) => {
      this.currentNotificationSubject.next(data);
    });

  }

  public addNewNotification(VCR: ViewContainerRef, data, NotificationsReferences, up = false) {
    //let componentFactory = this.CFR.resolveComponentFactory(NotificationComponent);

    //let childComponentRef = up ? VCR.createComponent(componentFactory, 0) : VCR.createComponent(componentFactory);
    //childComponentRef.instance.notification = data;
    //if (up) {
    //  NotificationsReferences.unshift(childComponentRef);
    //}
    //else
    //  NotificationsReferences.push(childComponentRef);

  }
  getAll(dataSource: any): Observable<Result | null> {
    return this.NotificationService.getAll(dataSource.page, dataSource.pageSize, dataSource.filter)
      .pipe(map(res => {
        if (res && (res as Result).success) {
          return res;
        }
      }));
  }

  markAsSeen(iD: number): Observable<Result> {
    return this.NotificationService.markAsSeen(iD);
  }

  async  RestartConnection() {
    try {
      if (this.hubConnection.state === signalR.HubConnectionState.Disconnected && !this.stoppedManual) {
        if (!this.authenticationService.currentUserValue) return;
        await this.hubConnection
          .start()
          .then(() => { })
          .catch(err => { });
      }
    }
    catch (err) {  }
  }
}
