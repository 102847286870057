import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Contract, Corporate, Course, CreateContractDto, Group, GroupDto, Trainee, User } from '../../../../shared/core/system-api';
import { ContractService } from '../../../../shared/services/contract.service';
import { CorporateService } from '../../../../shared/services/corporate.service';
import { CourseService } from '../../../../shared/services/course.service';
import { LanguageService } from '../../../../shared/services/Language.service';
import { UserService } from '../../../../shared/services/user.service';
import Swal from 'sweetalert2';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-create-new',
  templateUrl: './create-new.component.html',
  styleUrls: ['./create-new.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class CreateNewComponent implements OnInit {
  @Output() returnToList = new EventEmitter<any>();
  @Input() CorporateID: any;
  corporate: FormGroup;
  contract: FormGroup;
  trainee: FormGroup;
  selectedGroup: Group = new Group();
  EditselectedGroup: Group;
  contracts: Contract[] = [];
  trainees: Trainee[] = [];

  CoursesList: Course[] = [];
  ContractCoursesList: Course[] = [];
  ContractGroupsList: Group[] = [];
  contractGroups: GroupDto[] = [];

  public loading = false;
  datevalidate = false ;
  public corporateFormSubmitted = false;
  public contractFormSubmitted = false;
  public traineeFormSubmitted = false;
  public groupFormSubmitted = false;
  currentlang = '';
  public _translate: TranslateService;
  private unsubscribe: Subscription[] = [];
  constructor(public translate: TranslateService, private corporateService: CorporateService, private courseService: CourseService, private contractService: ContractService, private userService: UserService,
    private toastr: ToastrService, private formBuilder: FormBuilder, private languageService: LanguageService) {
    this._translate = translate;
    const currentLangSubscription = this.languageService.currentLang.subscribe(res => { this.currentlang = res; });
    this.unsubscribe.push(currentLangSubscription);
    const getCoursesList = this.courseService.getCoursesList().subscribe(result => {
      if (result.success) {
        this.CoursesList = result.data;
      }
    });
    this.unsubscribe.push(getCoursesList);
  }

 
  ngOnInit(): void {
    this.corporate = this.formBuilder.group({
      id: ['', ''],
      name: ['', Validators.required],
      headOfficeAddress: ['', Validators.required],
      locationOnGoogleMaps: [''],
    });
    this.contract = this.formBuilder.group({
      id: ['', ''],
      number: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', [Validators.required, this.IsConflictDateCheck.bind(this)]],
      name: ['', Validators.required],
      contactPersonName: ['', Validators.required],
      contactPersonJobTitle: ['', Validators.required],
      contactPersonMobileNumber: ['', Validators.required],
      contactPersonEmailAddress: ['', [Validators.email, Validators.required]],
      contactCourses: ['', Validators.required],
    });
    this.trainee = this.formBuilder.group({
      id: ['', ''],
      name: ['', Validators.required],
      entity: ['', Validators.required],
      jobTitle: ['', Validators.required],
      mobileNumber: ['', Validators.required],
      emailAddress: ['', [Validators.email, Validators.required]],
      nationalId: ['', [Validators.required]],
      contractNumber: ['', Validators.required],
      groupId: ['', Validators.required],
      courses: ['', Validators.required],
    });

  }

  IsConflictDateCheck(fieldControl: FormControl) {
    if (this.contract && this.Contractf && this.Contractf.startDate.value > this.Contractf.endDate.value) {
      return { 'IsConflictDate': true };
      // this.datevalidate == true ;

    }
    // console.log (this.Contractf.startDate.value , this.Contractf.endDate.value)
    // else if (this.contract.get('startDate').value > this.contract.get('endDate').value){
    //   // return { 'IsConflictDate': true };
    //  this.datevalidate == true ;
    // }
    return null;
  }

  public get Corporatef() { return this.corporate.controls; }
  public get Contractf() { return this.contract.controls; }
  public get traineef() { return this.trainee.controls; }

  ngAfterViewInit() {
    if (this.CorporateID) {
      const get = this.corporateService.get(this.CorporateID).subscribe(res => {
        if (res) {
          this.corporate.patchValue({
            id: res.data.id,
            name: res.data.name,
            headOfficeAddress: res.data.headOfficeAddress,
            locationOnGoogleMaps: res.data.locationOnGoogleMaps,
          });
          this.contracts = res.data.contracts;
          this.trainees = res.data.trainees;
          console.log(this.trainees);
          }
        });
      this.unsubscribe.push(get);
      
    }
  }

  onSubmitCorporateForm(stepper: MatStepper) {
    if (this.loading)
      return;
    this.corporateFormSubmitted = true;
    this.loading = true;

    if (this.corporate.invalid) {
      this.loading = false;
      return;
    }

    var corporate = new Corporate();
    corporate.id = this.Corporatef.id.value;
    corporate.name = this.Corporatef.name.value;
    corporate.headOfficeAddress = this.Corporatef.headOfficeAddress.value;
    corporate.locationOnGoogleMaps = this.Corporatef.locationOnGoogleMaps.value;
    if (corporate.id == 0 || corporate.id == null) {
      corporate.id = 0;
      this.corporateService.create(corporate).subscribe(res => {
        if (res.success) {
          this.corporate.patchValue({
            id: res.data.id
          });
          this.toastr.success(this.translate.instant('Corporate.CorporateCreated'));
          this.corporateFormSubmitted = false;
          stepper.next();
        }
        else {
          this.toastr.error(this.translate.instant('Corporate.' + res.code));
        }
      },
        error => {
          this.loading = false;
        });
    }
    else {
      this.corporateService.update(corporate).subscribe(res => {
        if (res.success) {
          this.toastr.success(this.translate.instant('Corporate.CorporateUpdated'));
          this.corporate.reset();
          this.corporateFormSubmitted = false;
          stepper.next();
        }
        else {
          this.toastr.error(this.translate.instant('Corporate.' + res.code));
        }
      },
        error => {
          this.loading = false;
        });
    }
    this.loading = false;
  }

  onSubmitContractForm() {
 
    if (this.loading)
      return;
    this.contractFormSubmitted = true;
    this.loading = true;

    if (this.contract.invalid || this.Corporatef.id.value == 0) {
      this.loading = false;
      return;
    }

    var contract = new CreateContractDto();

    contract.id = this.Contractf.id.value;
    contract.corporateId = this.Corporatef.id.value;
    contract.number = this.Contractf.number.value;
    contract.startDate = this.adjustDateForTimeOffset(new Date(this.Contractf.startDate.value));
    contract.endDate = this.adjustDateForTimeOffset(new Date(this.Contractf.endDate.value));
    contract.name = this.Contractf.name.value;
    contract.contactPersonName = this.Contractf.contactPersonName.value;
    contract.contactPersonJobTitle = this.Contractf.contactPersonJobTitle.value;
    contract.contactPersonMobileNumber = this.Contractf.contactPersonMobileNumber.value;
    contract.contactPersonEmailAddress = this.Contractf.contactPersonEmailAddress.value;
    contract.courses = <number[]>this.Contractf.contactCourses.value;
    contract.groups = this.contractGroups;
    if (contract.id == 0 || contract.id == null) {
      contract.id = 0;
      this.contractService.create(contract).subscribe(res => {
        console.log ("The Contract Response :-", res)
        if (res.success) {
          this.toastr.success(this.translate.instant('Corporate.ContractCreated'));
          this.contracts.push(res.data);
          this.contract.reset();
          this.step = 0;
          this.selectedGroup = new Group();
          this.EditselectedGroup = null;
          this.contractGroups = [];
          this.contractFormSubmitted = false;
        }
        else {
          this.toastr.error(this.translate.instant('Corporate.' + res.code));
        }
      },
        error => {
          this.loading = false;
        });
    }
    else {
      this.contractService.update(contract).subscribe(res => {
        console.log ("The Contract Response :-", res)

        if (res.success) {
          this.toastr.success(this.translate.instant('Corporate.ContractUpdated'));
          this.contracts = this.contracts.filter(x => x.id != contract.id);
          this.contracts.push(res.data);
          this.contract.reset();
          this.step = 0;
          this.selectedGroup = new Group();
          this.EditselectedGroup = null;
          this.contractGroups = [];
          this.contractFormSubmitted = false;
        }
        else {
          this.toastr.error(this.translate.instant('Corporate.' + res.code));
        }
      },
        error => {
          this.loading = false;
        });
    }
    this.loading = false;
  }

  onSubmitTraineeForm() {
    if (this.loading)
      return;
    this.traineeFormSubmitted = true;
    this.loading = true;

    if (this.trainee.invalid) {
      this.loading = false;
      return;
    }

    var trainee = new Trainee();

    trainee.id = this.traineef.id.value;
    trainee.name = this.traineef.name.value;
    trainee.entity = this.traineef.entity.value;
    trainee.jobTitle = this.traineef.jobTitle.value;
    trainee.mobileNumber = this.traineef.mobileNumber.value;
    trainee.emailAddress = this.traineef.emailAddress.value;
    trainee.nationalId = this.traineef.nationalId.value;
    trainee.contractNumber = this.traineef.contractNumber.value;
    trainee.courses = this.traineef.courses.value;
    trainee.groupId = this.traineef.groupId.value;

    if (trainee.id == 0 || trainee.id == null) {
      trainee.id = 0;
      this.userService.createTrainee(trainee).subscribe(res => {
        if (res.success) {
          this.toastr.success(this.translate.instant('Corporate.TraineeCreated'));
          this.trainees.push(res.data);
          this.trainee.reset();
          this.traineeFormSubmitted = false;
        }
        else {
          this.toastr.error(this.translate.instant('Corporate.' + res.code));
        }
      },
        error => {
          this.loading = false;
        });
    }
    else {
      this.userService.updateTrainee(trainee).subscribe(res => {
        if (res.success) {
          this.toastr.success(this.translate.instant('Corporate.TraineeUpdated'));
          this.trainees[this.trainees.findIndex(x => x.id == trainee.id)] = res.data;
          this.trainee.reset();
          this.traineeFormSubmitted = false;
        }
        else {
          this.toastr.error(this.translate.instant('Corporate.' + res.code));
        }
      },
        error => {
          this.loading = false;
        });
    }
    this.loading = false;
  }

  AddGroup() {
    if (this.loading)
      return;
    this.groupFormSubmitted = true;
    this.loading = true;

    if (!this.selectedGroup.number || !this.selectedGroup.name) {
      this.loading = false;
      return;
    }
    let index = this.contractGroups.find(x => x == this.EditselectedGroup);
    if (index)
    {
      index.number = this.selectedGroup.number;
      index.name = this.selectedGroup.name;
      this.EditselectedGroup = null;
    }
    else
    this.contractGroups.push(this.selectedGroup);
    this.selectedGroup = new Group();
    this.groupFormSubmitted = false;
    this.loading = false;
  }

  EditGroup(group) {
    this.selectedGroup.id = group.id;
    this.selectedGroup.number = group.number;
    this.selectedGroup.name = group.name;
    this.EditselectedGroup = group;
  }

  DeleteGroup(group) {
    Swal.fire({
      title: this.translate.instant('DEFAULT.confirmationTitle'),
      text: this.translate.instant('Corporate.confirmationGroupDelete'),
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('DEFAULT.yes'),
      cancelButtonText: this.translate.instant('DEFAULT.no')
    }).then((result) => {
      if (result.value) {
        this.contractGroups = this.contractGroups.filter(x => x != group);
        this.selectedGroup = new Group();
        this.EditselectedGroup = null;
      }
    });
  }

  OnChange(value, courses: any = null) {
    this.ContractCoursesList = [];
    this.contractService.getContractCourses(value).subscribe(
      data => {
        if (data.success) {
          this.ContractCoursesList = data.data;
          if (courses) {
            var contractCourses = this.ContractCoursesList.map(function (item) { return item.id; });
            var notMissing = courses.filter(item => contractCourses.indexOf(item) >= 0);
            this.trainee.patchValue({
              courses: notMissing
            });
          }
        }
      },
      error => {
        this.loading = false;
      });

    this.ContractGroupsList = [];
    this.contractService.getContractGroups(value).subscribe(
      data => {
        if (data.success) {
          this.ContractGroupsList = data.data;
        }
      },
      error => {
        this.loading = false;
      });
  }

  public return(): void {
    this.returnToList.emit(false);
  }

  step = 0;
  nextPage(stepper: MatStepper) {
    stepper.next();
  }
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  adjustDateForTimeOffset(dateToAdjust) {
    var offsetMs = dateToAdjust.getTimezoneOffset() * 60000;
    return new Date(dateToAdjust.getTime() - offsetMs);
  }

  contractEdit(id) {
    var c = this.contracts.find(x => x.id == id);
    if (c) {
      var contactCourses = c.contractCourse.map(function (item) { return item.courseId; });
      this.contract.patchValue({
        id: c.id,
        number: c.number,
        startDate: formatDate(c.startDate, 'yyyy-MM-dd', 'en'),
        endDate: formatDate(c.endDate, 'yyyy-MM-dd', 'en'),
        name: c.name,
        contactPersonName: c.contactPersonName,
        contactPersonJobTitle: c.contactPersonJobTitle,
        contactPersonMobileNumber: c.contactPersonMobileNumber,
        contactPersonEmailAddress: c.contactPersonEmailAddress,
        contactCourses: contactCourses,
      });
      this.contractGroups = c.contractGroup.map(function (item) {
        let GDTO = new GroupDto();
        GDTO.id = item.group.id;
        GDTO.number = item.group.number;
        GDTO.name = item.group.name;
        return GDTO;
      });
    }
  }

  contractDelete(id) {
    Swal.fire({
      title: this.translate.instant('DEFAULT.confirmationTitle'),
      text: this.translate.instant('Corporate.confirmationContractDelete'),
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('DEFAULT.yes'),
      cancelButtonText: this.translate.instant('DEFAULT.no')
    }).then((result) => {
      if (result.value) {
        const changeUserStatus = this.contractService.changeContractStatus(id, false).subscribe(
          data => {
            if (data.success) {
              this.toastr.success(this.translate.instant('Corporate.ContractDeleted'));
              this.contracts = this.contracts.filter(x => x.id != id);
            }
          });
        this.unsubscribe.push(changeUserStatus);
      }
    });
  }

  traineeEdit(id,contractID) {
    debugger;
    var t = this.trainees.find(x => x.id == id && x.contractNumber == contractID);
    if (t) {
      var courses = t.courses;
      this.OnChange(t.contractNumber, courses);
      this.trainee.patchValue({
        id: t.id,
        name: t.name,
        entity: t.entity,
        jobTitle: t.jobTitle,
        mobileNumber: t.mobileNumber,
        emailAddress: t.emailAddress,
        contractNumber: t.contractNumber,
        groupId: t.groupId,
      });
    }
  }

  traineeDelete(id, contractID) {
    Swal.fire({
      title: this.translate.instant('DEFAULT.confirmationTitle'),
      text: this.translate.instant('Corporate.confirmationTraineeDelete'),
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('DEFAULT.yes'),
      cancelButtonText: this.translate.instant('DEFAULT.no')
    }).then((result) => {
      if (result.value) {
        const changeUserStatus = this.userService.changeUserStatus(id, false, contractID).subscribe(
          data => {
            if (data.success) {
              this.toastr.success(this.translate.instant('Corporate.TraineeDeleted'));
              this.trainees = this.trainees.filter(x => x.id != id);
            }
          });
        this.unsubscribe.push(changeUserStatus);
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }

  
}
