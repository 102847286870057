import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { CourseQuestions, UserCourseExams } from '../../../shared/core/system-api';
import { QuestionAnswer } from '../../../shared/models/SelectList.model';
import { CourseExamsService } from '../../../shared/services/courseExams.service';
 
import { CourseQuestionsService } from '../../../shared/services/courseQuestions.service';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class QuizComponent implements OnInit {
  questionList: any = [];
  score: number = 0;
  id: number = 0;
  answerList: QuestionAnswer[] = [];
  questionSubmitted: boolean = false;
  finalScore: number = 0;
  level: string = '';
  userCourse: number = 0;
  isStart: string;

  constructor(private examService: CourseQuestionsService, 
       private _Activatedroute: ActivatedRoute,
       private toastr: ToastrService,
       public translate: TranslateService,
       private courseExamService: CourseExamsService,
       private route: ActivatedRoute,
       private router: Router) {
    
  }

  ngOnInit() {

    this.id = Number(this.route.snapshot.queryParamMap.get('id'));
    this.userCourse = Number(this.route.snapshot.queryParamMap.get('UserCourse'));
    this.isStart = this.route.snapshot.queryParamMap.get('IsStart');

    this.examService.getCourseQuestionsList(this.id).subscribe(
      data => {
        this.questionList = data.data;
      }
    );
  }

  radioChange(e, rightAnswer, questionIndex) {
    
    if (rightAnswer == e.value)
      this.score = 1;
    else
      this.score = 0;
    
    if (!this.answerList.some(e => e.QuestionIdex == questionIndex)) {
      this.answerList.push({ QuestionIdex: questionIndex, QuestionDegree: this.score });
    } else {
      let newItem = { QuestionIdex: questionIndex, QuestionDegree: this.score };
      let answer = this.answerList.find(e => e.QuestionIdex == questionIndex);
      let index = this.answerList.indexOf(answer);

      this.answerList[index] = newItem;
    }
  
  }

  SaveAnswers() {
    this.finalScore = 0;
    this.questionSubmitted = false;

    Swal.fire({
      title: this.translate.instant('DEFAULT.confirmationTitle'),
      text: this.translate.instant('CreateCourse.ConfirmAnswer'),
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('DEFAULT.yes'),
      cancelButtonText: this.translate.instant('DEFAULT.no')
    }).then((result) => {
      if (result.value) {
         console.log ("tha answer list :----", this.answerList)
        this.answerList.forEach(e => {
          this.finalScore = this.finalScore + e.QuestionDegree;
        });
        this.setLevel(this.finalScore);
        var exam = new UserCourseExams();
        exam.userCourseId = this.userCourse;
      
        if (this.isStart =='true') {
          exam.examTakenBefore = true;
          exam.degreeBefore = this.finalScore;
        }
        else
        {
          exam.examTakenAfter = true;
          exam.degreeAfter = this.finalScore; 
        }

        console.log(" the mark calculated is :---- ", this.finalScore)

        setTimeout(() => {
          this.router.navigateByUrl('/StudentProfile');
        }, 5000);

        this.courseExamService.create(exam).subscribe(data => {
          console.log ("The quiz Submit Response :----",data)
          this.questionSubmitted = true;
        });
      }
    });
 
 
  }
  setLevel(score) {
    switch (score) {
      case score >= 6 :
        this.level = 'مرتفع'
      case score > 5:
        this.level = 'متوسط'

      default:
        this.level = 'ضعيف'
    }
  }
  ResetAnswers() {
    this.questionSubmitted = false;
    this.answerList = [];
    this.finalScore = 0;
  }
}
