import { Component, OnInit } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { CourseService } from '../../../shared/services/course.service';
import { Subscription } from 'rxjs';
import { LanguageService } from '../../../shared/services/Language.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  items: any[] = [];
  SearchText: string;
  Searched: boolean = false;
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  currentlang: string;

  constructor(private route: ActivatedRoute, private languageService: LanguageService, private courseService: CourseService)
  {
    const currentLangSubscription = this.languageService.currentLang.subscribe(res => { this.currentlang = res; });
  }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      this.SearchText = params.get('text');
     
      if (this.SearchText && this.SearchText.trim() != "")
        this.courseService.search(this.SearchText).subscribe(result => {
          if (result.success) {
            this.Searched = true
            this.items = result.data;
          }
        });
    });
  }

  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }

}
