export interface SelectList {
  Key?: string;
  Value: any;
  KeyAr?: string;
  isUserGroup?: Boolean;
}

export class SelectOptions {
  Key: string;
  Value: any;
}

export class QuestionAnswer {
  QuestionIdex: number;
  QuestionDegree: number;
}
