import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2, NgZone, ViewContainerRef, ComponentRef, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ChangePasswordComponent } from '../../../pages/auth/change-password/change-password.component';
import { environment } from '../../../../environments/environment';
import { UserDto } from '../../../shared/core/system-api';
import { NotificationService } from '../../../shared/services/notification.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { LanguageService } from '../../../shared/services/Language.service';
import { SWALMSGSService } from '../../../shared/services/swal-msgs.service';
import { AbilityService } from '../../../shared/services/ability.service';
import { SocialAuthService } from '../../../shared/socialmedia/socialauth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class HeaderComponent implements OnInit {


  currentUser: UserDto;

  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  currentlang: string;
  SearchText: string="";
  UserName: string = "";

  public currentUser$: Observable<UserDto>;
  private currentUserSubject: BehaviorSubject<UserDto>;

  //@ViewChild("notificationList", { read: ViewContainerRef })
  //VCRNotificationList: ViewContainerRef;

  //NotificationsReferences = Array<ComponentRef<NotificationComponent>>()

  constructor(private router: Router,
    private notificationService: NotificationService,
    public authenticationService: AuthenticationService,
    private languageService: LanguageService,
    public translate: TranslateService,
    private renderer: Renderer2,
    public dialog: MatDialog,
    //private SWAL: SWALMSGSService,
    //private ability: AbilityService,
    //private _ngZone: NgZone,
    //private toastr: ToastrService,
    private authService: SocialAuthService ) {

    this.currentUserSubject = new BehaviorSubject<UserDto>(null);

    this.currentUser$ = this.currentUserSubject.asObservable();
    //us sa
    const currentLangSubscription = this.languageService.currentLang.subscribe(res => {  this.currentlang = res; });
    this.unsubscribe.push(currentLangSubscription);
    if (this.currentlang == 'us') {
      this.renderer.setAttribute(document.querySelector('html'), 'dir', 'ltr');
    } else {
      this.renderer.setAttribute(document.querySelector('html'), 'dir', 'rtl');
    }
  }
  usernameAbridged() {
    if (this.currentUser) {
      let n = this.currentUser.userName.split(' ');
      let nConcat = n.map((n) => n[0]).join('');
      return nConcat.trim().substr(0, 2).toUpperCase();
    }
    return "";
  }
  ngOnInit(): void {

    if (this.currentUser) {
      this.UserName = this.usernameAbridged();
      //this.notificationService.startConnection();
      //this.DrawNotificationList();
      //const currentNotificationSubscription = this.notificationService.currentNotification.subscribe(notification => {

      //  //if (!notification) {
      //  //  return;
      //  //}
      //  //else {
      //  //  this.notificationService.addNewNotification(this.VCRNotificationList, notification, this.NotificationsReferences, true);
      //  //  this.languageService.currentLang.subscribe(userLang => {
      //  //    if (userLang == 'us') {
      //  //      this.toastr.success(notification.message, notification.receiver.fullName);
      //  //    }
      //  //    else {
      //  //      this.toastr.success(notification.messageAr, notification.receiver.fullName);
      //  //    }
      //  //  });
      //  //}
      //});
      //this.unsubscribe.push(currentNotificationSubscription);

    }
  }
  ngOnChanges() {
    this.UserName = this.usernameAbridged();
  }
  DrawNotificationList() {
    //const notificationSubscription = this.notificationService.getAll({
    //  page: 0,
    //  pageSize: 0,//This is filled in backend using appsetting 
    //  filter: []
    //}).subscribe(notifications => {

    //  if (notifications.data.length > 0) {
    //    for (var i = 0; i < notifications.data.length; i++) {
    //      this.notificationService.addNewNotification(this.VCRNotificationList, notifications.data[i], this.NotificationsReferences);
    //    }
    //  }

    //});
    //this.unsubscribe.push(notificationSubscription);

  }

  @Input()
  set CurrentUser(currentUser) {
    this.currentUser = currentUser;
    this.currentUserSubject.next(this.currentUser);
  }

  @Input()
  set CurrentLang(currentLang) {

    this.currentlang = currentLang;
  }

  switchLanguage(code) {
    this.languageService.switchLanguage(code);
    //window.location.reload();
    if (code === 'us') {
      this.renderer.setAttribute(document.querySelector('html'), 'dir', 'ltr');
    } else {
      this.renderer.setAttribute(document.querySelector('html'), 'dir', 'rtl');
    }
   
    
  }

  logout() {
    this.authenticationService.logout();
    this.notificationService.closeConnection();
    this.currentUserSubject.next(null);
    this.unsubscribe.forEach(sb => sb.unsubscribe());
    this.router.navigate(['']);
    this.authService.signOut();
  }
  Search() {
    if (this.SearchText.trim() != "")
    this.router.navigate(['Search', { text: this.SearchText }]);
  }
  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());

    window['HeaderComponent'] = null;
  }
  changePassword() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50%";
    dialogConfig.direction = this.currentlang === 'sa' ? "rtl" : "ltr";
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(ChangePasswordComponent, dialogConfig);
  }
}
