  import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { LanguageService } from '../../../shared/services/Language.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {

  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  currentlang: string;
  constructor(
    private languageService: LanguageService) {
    const currentLangSubscription = this.languageService.currentLang.subscribe(res => { this.currentlang = res; });
    this.unsubscribe.push(currentLangSubscription);}

  ngOnInit(): void {
  }

}
