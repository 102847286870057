export {
  SocialAuthService,
  SocialAuthServiceConfig,
} from './socialauth.service';
export { SocialLoginModule } from './sociallogin.module';
export { SocialUser } from '../models/social-user';
export { LoginProvider } from '../models/login-provider';
export { GoogleLoginProvider } from './providers/google-login-provider';
export { FacebookLoginProvider } from './providers/facebook-login-provider';
 
