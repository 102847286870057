import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthenticationService } from './authentication.service';
 
import { debug } from 'util';

@Injectable({ providedIn: 'root' })
export class AbilityService {



  constructor(private authenticationService: AuthenticationService) { }

  get user() {
    return this.authenticationService.currentUserValue;
  }

  canAccessAlarm(alarmResponsibleGroups: any, userID: any, type: string): boolean {
    return true;// this.user && (this.user.isSuperAdmin
     // || (this.user.groups.filter(x => alarmResponsibleGroups.filter(z => z == x).length > 0).length > 0 && this.user.id != userID) || (this.user.id == userID && type == 'status') );
  }

  can(action: string, onLevel: any, type: string) {
    return true;
    //var has = null;
    //action = action.toLowerCase();
    //onLevel = onLevel.toLowerCase();
    //type = type.toLowerCase();
    //if (has == null)
    //  has = this.user && (this.user.isSuperAdmin ||
    //    (type == 'role'
    //      && this.user.roles.filter(x =>
    //        x.id == onLevel
    //        && x.rolePrivileges.filter(z => z.fkPrivilegesNavigation.privilegeTitle.toLowerCase().trim() == action).length > 0
    //      ).length > 0)
    //    ||
    //    (type == 'menu'
    //      && this.user.menu.filter(z => z.title.toLowerCase().trim() == onLevel
    //        || z.inverseParent.filter(x => x.title.toLowerCase().trim() == onLevel).length > 0).length > 0
    //    )
    //  );
    //return has == null ? false : has;
  }

  canBulk(action: string) {
    return true;
    //var has = null;
    //action = action.toLowerCase();
    //if (has == null)
    //  has = this.user && (this.user.isSuperAdmin || (this.user.bulkActions && this.user.bulkActions.find(x => x.toLowerCase().trim() == action) != null));
    //return has == null ? false : has;
  }
}
