import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { UserExamService } from '../../../../shared/core/system-api';
import { corporateDetails } from '../../../../shared/models/corporateDetails.model';
import { CorporateService } from '../../../../shared/services/corporate.service';
import { ExcelService } from '../../../../shared/services/excel.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
 
export class DetailsComponent implements OnInit  {
  displayedColumns: string[] = ['name','groupName' , 'quizResults', 'complete', 'quizResultAfter'];
  corporate: any;
  corporateDetails: corporateDetails;
  corporateDetailsToExcel: corporateDetails[] = [];
  headersToExcel: any[] = [];
  private unsubscribe: Subscription[] = [];
  corprateId: number = 0;
  

  constructor(public translate: TranslateService, private route: ActivatedRoute,
    private corporateService: CorporateService, public excelService: ExcelService, private userExamService: UserExamService) {
  }
 

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.corprateId = Number(params.get('corporateID'));
       this.draw(this.corprateId);
    });
  }

  draw(corporateID) {
    //debugger;
    const getCorporateDetails = this.corporateService.getCorporateDetails(corporateID).subscribe(
      data => {
        if (data.success) {
          this.corporate = data.data;
        }
      });
    this.unsubscribe.push(getCorporateDetails);
  }
 
  public exportAsXLSX(): void {
    this.generateCorporateDetailsToExcel();
    this.excelService.exportAsExcelFile(this.corporateDetailsToExcel, this.corporate.name);
  }
  generateCorporateDetailsToExcel() {
    this.corporateDetails = new corporateDetails();
    this.corporateDetails.contractName = this.translate.instant('Corporate.ContractName');
    this.corporateDetails.contractNumber = this.translate.instant('Corporate.ContractNo');
    this.corporateDetails.courseName = this.translate.instant('Corporate.Course');
    this.corporateDetails.traineeName = this.translate.instant('Corporate.TraineeName');
    this.corporateDetails.traineeEmail = this.translate.instant('Corporate.EmailAddress');
    this.corporateDetails.traineeQuizResultBefore = this.translate.instant('Corporate.QuizResults') + ' ' + this.translate.instant('Corporate.Before');
    this.corporateDetails.traineeQuizResultAfter = this.translate.instant('Corporate.QuizResults') + ' ' + this.translate.instant('Corporate.After');
    this.corporateDetails.traineeComplete = this.translate.instant('Corporate.Complete');
    this.corporateDetailsToExcel.push(this.corporateDetails);
    if (this.corporate.contracts.length > 0) {
      for (var iContract = 0; iContract < this.corporate.contracts.length; iContract++) {
        if (this.corporate.contracts[iContract].courses.length > 0) {
          for (var iCourse = 0; iCourse < this.corporate.contracts[iContract].courses.length; iCourse++) {
            if (this.corporate.contracts[iContract].courses[iCourse].trainees.length > 0) {
              for (var iTrainee = 0; iTrainee < this.corporate.contracts[iContract].courses[iCourse].trainees.length; iTrainee++) {
                this.corporateDetails = new corporateDetails();
                this.corporateDetails.contractName = this.corporate.contracts[iContract].name;
                this.corporateDetails.contractNumber = this.corporate.contracts[iContract].number;
                this.corporateDetails.courseName = this.corporate.contracts[iContract].courses[iCourse].name;
                this.corporateDetails.traineeName = this.corporate.contracts[iContract].courses[iCourse].trainees[iTrainee].name;
                this.corporateDetails.traineeEmail = this.corporate.contracts[iContract].courses[iCourse].trainees[iTrainee].email;
                this.corporateDetails.traineeQuizResultBefore = this.corporate.contracts[iContract].courses[iCourse].trainees[iTrainee].quizResultBefore;
                  // this.corporateDetails.traineeQuizResultAfter = 'not taken'; 
                this.corporateDetails.traineeQuizResultAfter = this.corporate.contracts[iContract].courses[iCourse].trainees[iTrainee].quizResultAfter;
                this.corporateDetails.traineeComplete = this.corporate.contracts[iContract].courses[iCourse].trainees[iTrainee].complete;
                this.corporateDetailsToExcel.push(this.corporateDetails);
              }
            }
            else {
              this.corporateDetails = new corporateDetails();
              this.corporateDetails.contractName = this.corporate.contracts[iContract].name;
              this.corporateDetails.contractNumber = this.corporate.contracts[iContract].number;
              this.corporateDetails.courseName = this.corporate.contracts[iContract].courses[iCourse].name;
              this.corporateDetails.traineeName = this.translate.instant('DEFAULT.null');
              this.corporateDetails.traineeEmail = this.translate.instant('DEFAULT.null');
              this.corporateDetails.traineeQuizResultBefore = this.translate.instant('DEFAULT.null');
              this.corporateDetails.traineeQuizResultAfter = this.translate.instant('DEFAULT.null');
              this.corporateDetails.traineeComplete = this.translate.instant('DEFAULT.null');
              this.corporateDetailsToExcel.push(this.corporateDetails);
            }
          }
        }
        else {
          this.corporateDetails = new corporateDetails();
          this.corporateDetails.contractName = this.corporate.contracts[iContract].name;
          this.corporateDetails.contractNumber = this.corporate.contracts[iContract].number;
          this.corporateDetails.courseName = this.translate.instant('DEFAULT.null');
          this.corporateDetails.traineeName = this.translate.instant('DEFAULT.null');
          this.corporateDetails.traineeEmail = this.translate.instant('DEFAULT.null');
          this.corporateDetails.traineeQuizResultBefore = this.translate.instant('DEFAULT.null');
          this.corporateDetails.traineeQuizResultAfter = this.translate.instant('DEFAULT.null');
          this.corporateDetails.traineeComplete = this.translate.instant('DEFAULT.null');
          this.corporateDetailsToExcel.push(this.corporateDetails);
        }
      }
    }
    else {
      this.corporateDetails = new corporateDetails();
      this.corporateDetails.contractName = this.translate.instant('DEFAULT.null');
      this.corporateDetails.contractNumber = this.translate.instant('DEFAULT.null');
      this.corporateDetails.courseName = this.translate.instant('DEFAULT.null');
      this.corporateDetails.traineeName = this.translate.instant('DEFAULT.null');
      this.corporateDetails.traineeEmail = this.translate.instant('DEFAULT.null');
      this.corporateDetails.traineeQuizResultBefore = this.translate.instant('DEFAULT.null');
      this.corporateDetails.traineeQuizResultAfter = this.translate.instant('DEFAULT.null');
      this.corporateDetails.traineeComplete = this.translate.instant('DEFAULT.null');
      this.corporateDetailsToExcel.push(this.corporateDetails);
    }
  }
  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }
 

  //applyFilter(event: Event, data) {
  //  debugger;
  // var dataSource = new MatTableDataSource(data);

  //  const filterValue = (event.target as HTMLInputElement).value;
  //  if (filterValue.length > 0) {
  //    this.hasFilter = true;
  //  } else {
  //    this.hasFilter = false;
  //  }
  //  dataSource.filter = filterValue.trim().toLowerCase();
  //  this.filteredList = dataSource.filteredData as unknown as any[];
  //}
}
