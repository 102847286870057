import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '../../../shared/services/Language.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { Subscription } from 'rxjs';
import { UserDto } from '../../../shared/core/system-api';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  public registerForm: FormGroup;
  public loading = false;
  public submitted = false;
  public Dublicate = false;
  public returnUrl: string;
  currentlang: string;
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private languageService: LanguageService

  ) {
    const currentLangSubscription = this.languageService.currentLang.subscribe(res => { this.currentlang = res; });
    this.unsubscribe.push(currentLangSubscription);

  }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d$@$!%*?&].{6,}')]],
      allowsendemail: ['', '']
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = '';

  }

  // convenience getter for easy access to form fields
  public get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.loading = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      this.loading = false;
      return;
    }

    var user = new UserDto();
    user.userName = this.f.firstname.value + " " + this.f.lastname.value;
    user.email = this.f.email.value;
    user.password = this.f.password.value;
    user.allowSendEmail = this.f.allowsendemail.value;
    const loginSubscription = this.authenticationService.register(user)
      .subscribe(
        data => {
          if (data) {
              this.router.navigate([this.returnUrl]);
            }
          else {
            this.Dublicate = true;
            this.loading = false;
          }
        },
        error => {
          // this.alertService.error("There is something went wrong, Please check with the IT Department.");
          alert("There is something went wrong, Please check with the IT Department.");
          this.loading = false;
        });

    this.unsubscribe.push(loginSubscription);
  }
  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }
}
