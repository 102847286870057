export enum Language {
  us = 1,
  sa = 2
}
export enum ExportType {
  Excel = 1,
  PPT = 2,
  PDF = 3
}

export enum UserType {
  Normal = 1,
  Admin = 2,
  Instructor = 3,
  Corporate = 4
}

 
