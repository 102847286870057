import { Injectable } from '@angular/core';
import { CategoryService as RemoteService, Result, Filter, Category } from '../core/system-api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private CategoryService: RemoteService) { }

  page: number = 1;
  pagesize: number = 5;
  filter: Filter[] = [];
  Categorydto: Category = null;

  get(Id: number | undefined): Observable<Result | null> {
    return this.CategoryService.get(Id)
  }

  getAll(dataSource: any): Observable<Result | null> {
    return this.CategoryService.getAll(dataSource.page, dataSource.pageSize, dataSource.filter)
      .pipe(map(res => {
        if (res && res.success) {
          return res;
        }
      }));
  }
  getCategorysList(Id: number = 0): Observable<Result | null> { return this.CategoryService.getCategorysList(Id) }


  changeCategoryStatus(ID, Status): Observable<Result | null> {
    return this.CategoryService.changeCategoryStatus(ID, Status).pipe(map(res => {
      if (res)
        return res;
    }));
  }

  create(userDto): Observable<Category | null> {
    return this.CategoryService.create(userDto)
      .pipe(map(res => {
        if (res && res.success)
          return <Category>res.data;
      }));
  }

  update(userDto): Observable<Category | null> {
    return this.CategoryService.update(userDto)
      .pipe(map(res => {
        if (res && res.success)
          return <Category>res.data;
      }));
  }
  getCategoryCourses(CategoryID: number): Observable<Result | null> { return this.CategoryService.getCategoryCourses(CategoryID) }
}
