import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { LanguageService } from '../../../shared/services/Language.service';

import { Subscription } from 'rxjs';
import { UserService } from '../../../shared/services/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ResetPassword',
  templateUrl: './ResetPassword.component.html',
  styleUrls: ['./ResetPassword.component.css']
})
export class ResetPasswordComponent implements OnInit {

  public ResetPasswordForm: FormGroup;
  public loading = false;
  public submitted = false;
  public WrongUser = false;
  public InActiveUser = false;
  public returnUrl: string;
  currentlang: string;
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
    token: any;


  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private render: Renderer2,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private languageService: LanguageService,
    private toastr: ToastrService

  ) {
    const currentLangSubscription = this.languageService.currentLang.subscribe(res => { this.currentlang = res; });
    this.token = this.route.snapshot.queryParams['access_token'];

  }

  ngOnInit() {
    this.ResetPasswordForm = this.formBuilder.group({
      pass: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d$@$!%*?&].{6,}')]],
      confirmPass: ['',]
    },
      { validator: this.checkMatchingPasswords('pass', 'confirmPass') }
    );


    // load default styles
    this.render.addClass(document.body, 'login-container');
    this.render.addClass(document.body, 'login-backgroud');
  }


  // convenience getter for easy access to form fields
  public get f() { return this.ResetPasswordForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.loading = true;

    // stop here if form is invalid
    if (this.ResetPasswordForm.invalid) {
      this.loading = false;
      return;
    }


    const ResetPasswordSubscription = this.userService.changeCurrentUserPassword(this.token, this.f.pass.value).subscribe(
      res => {
        if (res.success) {
          if (this.currentlang == "us")
            this.toastr.success("Password Changed Successfully");
            else
            this.toastr.success("تم تغيير الرقم السري بنجاح");
          this.authenticationService.logout();
          this.router.navigate(['/SignIn']);
        }
        else {
          this.WrongUser = true;
        }
      },
      error => {
        if (this.currentlang == "us")
          this.toastr.error("Something went wrong. Please contact the IT department.");
        else
          this.toastr.error("حدث خطأ ما. يرجى التواصل مع قسم تقنية المعلومات");
        this.loading = false;
      });

    this.unsubscribe.push(ResetPasswordSubscription);
  }
  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }

  checkMatchingPasswords(passwordKey: string, ConfirmationKey: string) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey], ConfirmationInput = group.controls[ConfirmationKey];
      if (passwordInput.value !== ConfirmationInput.value) {
        return ConfirmationInput.setErrors({ notEqual: true });
      }
      else {
        return ConfirmationInput.setErrors(null);
      }
    }
  }
}
