import { Injectable } from '@angular/core';
import { UserCourseService as RemoteService, Result, Filter, UserCourse } from '../core/system-api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserCourseService {

  constructor(private UserCourseService: RemoteService) { }

  page: number = 1;
  pagesize: number = 5;
  filter: Filter[] = [];
  UserCoursedto: UserCourse = null;

  get(Id: number | undefined): Observable<UserCourse | null> {
    return this.UserCourseService.get(Id).pipe(map(res => {
      if (res && res.success) {
        return <UserCourse>res.data;
      }
    }));
  }

  getAll(dataSource: any): Observable<Result | null> {
    return this.UserCourseService.getAll(dataSource.page, dataSource.pageSize, dataSource.filter)
      .pipe(map(res => {
        if (res && res.success) {
          return res;
        }
      }));
  }
  getUserCoursesList(Id: number = 0): Observable<Result | null> { return this.UserCourseService.getUserCoursesList(Id) }


  changeUserCourseStatus(ID, Status): Observable<Result | null> {
    return this.UserCourseService.changeUserCourseStatus(ID, Status).pipe(map(res => {
      if (res)
        return res;
    }));
  }

  create(userDto): Observable<UserCourse | null> {
    return this.UserCourseService.create(userDto)
      .pipe(map(res => {
        if (res && res.success)
          return <UserCourse>res.data;
      }));
  }

  update(userDto): Observable<UserCourse | null> {
    return this.UserCourseService.update(userDto)
      .pipe(map(res => {
        if (res && res.success)
          return <UserCourse>res.data;
      }));
  }

  isUserCourse(CourseID): Observable<UserCourse | null> {
    return this.UserCourseService.isUserCourse(CourseID)
      .pipe(map(res => {
        if (res && res.success)
          return <UserCourse>res.data;
      }));
  }
  addUserCourseVideo(VideoID,CourseID): Observable<UserCourse | null> {
    return this.UserCourseService.addUserCourseVideo(VideoID,CourseID)
      .pipe(map(res => {
        if (res && res.success)
          return <UserCourse>res.data;
      }));
  }
}
