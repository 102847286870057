import { NgModule } from "@angular/core";
 
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../shared/guard/auth-guard";
import { AdminHomeComponent } from "./admin-home/admin-home.component";
import { CorperateComponent } from "./corperate/corperate.component";
import { DetailsComponent } from "./corperate/details/details.component";
import { CreateCourseComponent } from "./create-course/create-course.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { UsersComponent } from "./users/users.component";
import { CoursesListComponent } from './create-course/courses-list/courses-list.component';
import { EditCourseComponent } from './edit-course/edit-course.component';
 
 
const routes: Routes = [
  { path: 'AdminSite', component: CorperateComponent, canActivate: [AuthGuard] },
  { path: 'AdminSite/Home', component: AdminHomeComponent, canActivate: [AuthGuard] },
  { path: 'AdminSite/Dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'AdminSite/Corperate', component: CorperateComponent, canActivate: [AuthGuard] },
  { path: 'AdminSite/Corporate/Details', component: DetailsComponent, canActivate: [AuthGuard] },
  { path: 'AdminSite/CreateCourse', component: CreateCourseComponent, canActivate: [AuthGuard] },
  { path: 'AdminSite/EditCourse', component: EditCourseComponent, canActivate: [AuthGuard] },
  { path: 'AdminSite/Courses', component: CoursesListComponent, canActivate: [AuthGuard] },

  { path: 'AdminSite/UsersManager', component: UsersComponent },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  declarations: []
})

export class AdminRoutingModule {

}
