import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { LanguageService } from '../../../../shared/services/Language.service';

@Component({
  selector: 'app-intro-slider',
  templateUrl: './intro-slider.component.html',
  styleUrls: ['./intro-slider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IntroSliderComponent implements OnInit {

  currentlang: string;
  private unsubscribe: Subscription[] = [];

  constructor(private languageService: LanguageService) {
    const currentLangSubscription = this.languageService.currentLang.subscribe(res => { this.currentlang = res; });
    this.unsubscribe.push(currentLangSubscription);
  }

  ngOnInit(): void {
  }
  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }
}
