import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { Router } from '@angular/router';
import { Course, CourseVides } from '../../../shared/core/system-api';
import { CategoryService } from '../../../shared/services/category.service';
import { CourseService } from '../../../shared/services/course.service';
import { VideosService } from '../../../shared/core/system-api';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { SelectList, SelectOptions } from '../../../shared/models/SelectList.model';
import { CourseQuestionsService } from '../../../shared/services/courseQuestions.service';
import { DISABLED } from '@angular/forms/src/model';

@Component({
  selector: 'app-create-course',
  templateUrl: './create-course.component.html',
  styleUrls: ['./create-course.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateCourseComponent implements OnInit {
  cats: any[] = [];
  vedios: any[] = [];
  questions: any[] = [];
  vedioAdd = false;
  questionAdded = false;
  catName = '';
  courses: FormGroup;
  courseVedios: FormGroup;
  courseQuestions: FormGroup;
  selectedAnswer: number = 0;
  public loading = false;
  public submitted = false;
  public vedioSubmitted = false;
  public questionSubmitted = false;


  courseId: number = 0;
  rowIndex = 0;

  folderPath = 'CourseImages/';
  imageName = '';
  vedioimageName = '';
  public progress: number;
  public message: string;
  @Output() public onUploadFinished = new EventEmitter();

  AnswersList: SelectOptions[] = [];

  constructor(private router: Router, 
     private categoryService: CategoryService,
     private formBuilder: FormBuilder, 
     private questionServices: CourseQuestionsService,
     private courseService: CourseService, 
     private vedioService: VideosService, 
     private toastr: ToastrService, 
     private http: HttpClient) { }

  ngOnInit() {
    this.courses = this.formBuilder.group({
      category: ['', Validators.required],
      price: ['', Validators.required],
      name: ['', Validators.required],
      details: ['', Validators.required],
      arname: ['', Validators.required],
      ardetials: ['', Validators.required],
    });

    this.courseVedios = this.formBuilder.group({
      vedioName: ['', Validators.required],
      vedioArName: ['', Validators.required],
      vedioPath: ['', Validators.required],

    });

    this.courseQuestions = this.formBuilder.group({
      question: ['', Validators.required],
      answer1: ['', Validators.required],
      answer2: ['' , Validators.required],
      answer3: ['', Validators.required],
      answer4: ['', Validators.required],
      rightAnswer: ['', Validators.required],

    });
   
    this.categoryService.getCategorysList(0).subscribe(result => {
      if (result.success) {
        this.cats = result.data;
      }
    });

    this.setAnsewrsInputDisabled();
  }

  setCatName(catName) {
    this.catName = catName;
  }
 

  public get f() { return this.courses.controls; }
  public get v() { return this.courseVedios.controls; }
  public get q() { return this.courseQuestions.controls; }

 //#region Region Question Sections

  onSubmit(stepper: MatStepper) {
    this.submitted = true;
    this.loading = true;

    // stop here if form is invalid
    if (this.courses.invalid) {
      this.loading = false;
      return;
    }

    var course = new Course();
    course.categoryId = this.f.category.value;
    course.name = this.f.name.value;
    course.nameAr = this.f.arname.value;
    course.description = this.f.details.value;
    course.descriptionaR = this.f.ardetials.value;
    course.price = this.f.price.value;
    course.imagePath = this.folderPath+ this.imageName;
    
    this.courseService.create(course).subscribe(
      data => {
        if (data != null) {
          this.courseId = data.id;
          this.toastr.success("the course is created");
          stepper.next();
        } else
          this.toastr.error("The course name is already exist");
      },
      error => {
        this.toastr.error("There is something went wrong, Please check with the IT Department.");
        this.loading = false;
      });

    // stepper.next();
    this.loading = false;
  }

 //#endregion
 
  addVedio() {
   
    if (this.courseId == 0) {
      this.toastr.warning("Add Course first");
      return;
    }

    this.vedioSubmitted = true;
    this.loading = true;

    // stop here if form is invalid
    if (this.courseVedios.invalid) {
      this.loading = false;
      return;
    }
    var imageBeforeAdd = this.vedioimageName;
    this.vedios.push({
      vedioId: 0, courseId: this.courseId, name: this.v.vedioName.value, arName: this.v.vedioArName.value,
      vedioPath: this.v.vedioPath.value, thubnails: 'VedioThubnils/' + this.vedioimageName
    });
    this.vedioAdd = true;
    this.vedioSubmitted = false;
    this.courseVedios.reset();
    this.vedioimageName = imageBeforeAdd;
  }

  submitCourseVedios(stepper: MatStepper) {
    if (this.vedios.length == 0)
    {
      this.toastr.warning("Add vedios first");
      return;
    }
    this.vedioService.createBulk(this.vedios).subscribe(
      data => {
        if (data!= null) {
          this.vedioAdd = false;
          this.vedios = [];
          this.toastr.success("the vedios is added");

         //stepper.reset();
          stepper.next();
        }
     
      });
  }

  cancleVedio(stepper: MatStepper)
  {
    stepper.reset();
    stepper.previous();
  }

  public uploadFile(files: FileList, folderName: string) {
    this.imageName = '';
    if (files.length === 0) {
      return;
    }

    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload);

    this.courseService.upload(formData,folderName)
      .subscribe(
        event => {
          if (folderName =='CourseImages')  
            this.imageName = fileToUpload.name;
          else
            this.vedioimageName = fileToUpload.name;
        
          if (event.type === HttpEventType.UploadProgress)
            this.progress = Math.round(100 * event.loaded / event.total);
          else if (event.type === HttpEventType.Response) {
            this.message = 'Upload success.';
            this.onUploadFinished.emit(event.body);
          };
        });
  }
 
  //#region Region Question Sections

  setAnsewrsInputDisabled() {
    this.q.answer2.disable();
    this.q.answer3.disable();
    this.q.answer4.disable();
  }

  fillAnswerList(value, index) {
    if (value != null && index < 4 ) {
     
      switch (index +1) {
        case 1:
          this.q.answer2.enable();
          break;
        case 2:
          this.q.answer3.enable();
          break;
        case 3:
          this.q.answer4.enable();
          break;
      }
      
    }
    var answer = value.target.value;

    var answerObject = new SelectOptions();
    answerObject.Key = answer;
    answerObject.Value = index + 1;

    if (this.AnswersList[index] != null) {

      this.AnswersList.splice(index, 1, answerObject);
    }
    else {

      this.AnswersList.splice(index, 0, answerObject);
    }

  }

  setSelectedAnswer(value) {
    this.selectedAnswer = value;
  }

  addQuestion() {
    if (this.courseId == 0) {
      this.toastr.warning("Add Course first");
      return;
    }
   // debugger;
    if (this.AnswersList.length != 4) {
      this.toastr.info("please add the four answers");
      return;
    }
    if (this.selectedAnswer == 0) {
      this.toastr.info("please select the rigt answer");
      return;
    }

    this.questionSubmitted = true;
    this.loading = true;

    if (this.courseQuestions.invalid) {
      this.loading = false;
      this.toastr.info("All fields are mandatory");
      return;
    }
    //this.courseId = 1;
    this.questions.push({
      questionId: 0, courseId: this.courseId, questionTitle: this.q.question.value, answerOne: this.q.answer1.value,
      answerTwo: this.q.answer2.value, answerThree: this.q.answer3.value, answerFour: this.q.answer4.value, rightAnswer: (this.q.rightAnswer.value*1)
    });
    this.questionAdded = true;
    this.AnswersList = [];
    this.setAnsewrsInputDisabled();
   this.courseQuestions.reset();
  }

  deleteQuestion(index)
  {
    this.questions.splice(index, 1);
  }

  submitCourseQuestions(stepper: MatStepper) {
    //debugger;
    this.questionServices.createBulk(this.questions).subscribe(
      data => {
        this.questionAdded = false;
        this.questions = [];
        this.AnswersList = [];
        if (data != null) {
          this.toastr.success("the questions is added");
        } else
          this.toastr.info("this course already have questions");
       
         
        stepper.reset();
        stepper.previous();
      },
      err => {
      });
  }

 //#endregion Region  
}
