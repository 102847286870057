import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryService } from '../../../../shared/services/category.service';
import { LanguageService } from '../../../../shared/services/Language.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TopicsComponent implements OnInit {
  items: any[] = [];

  currentlang: string;
  private unsubscribe: Subscription[] = [];
  constructor(private router: Router, private categoryService: CategoryService, private languageService: LanguageService) {
    const currentLangSubscription = this.languageService.currentLang.subscribe(res => { this.currentlang = res; });
    this.unsubscribe.push(currentLangSubscription); }

  ngOnInit(): void {
    this.categoryService.getCategorysList().subscribe(result => {
      if (result.success) {
        this.items = result.data;
      }
    });
  }
  getCategoryCourses(ID) {
    this.router.navigate(['Topic', { categoryID: ID }]);
  }
  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }
}
