export const locale = {
  lang: 'en',
  data: {
    dateLang: 'en',
    Validation: {
      Required: "This field is required.",
      Email: "You have entered an invalid email.",
      ConflictDate: "End date must be greater than start date.",
    },
    DEFAULT: {
      Placeholder: "Write here...",
      Loading: "Loading...",
      yes: "Yes",
      no: "No",
      confirmationTitle: 'Are You Sure?',
      null: 'NULL',
    },
    HEADER: {
      Logout: "Logout",
      Language: "Language",
      DIR: "ltr",
      Notification: "Notification",
      ChangePassword: "Change Password",
      MyCourses: "My Courses",
      AdminSite: "Admin Site",
      AboutUs: "about us",
      JoinOurAcademy: "join our academy",
      SiteMap: "sitemap",
      ContactUs: "contact us",
      SearchOnCourse: "Search on Course",
      SignIn: "Sign In",
      SignUp: "Sign Up",
    },
    FOOTER: {
      WELCOME: "WELCOME...! NI Academy - Education Platform",
      NIAEP: "NI Academy - Education Platform [NIAEP]",
      Text: "treasures each child, and values the opportunity to help students learn and grow.Come be a part of our schools, whether as an involved parent, a student, a community volunteer, or a partner.Pulling together, we can accomplish our goal.",
      NEWSLETTERSIGNUP: "NEWSLETTER SIGNUP",
      ReceiveTheLatest: "Receive the latest useful information, daily.",
      EmailAddress: "Enter your e-mail Address",
      SignUp: "SIGNUP",
      FeelFreeContactUs: "Feel free to contact us:",
      Info: "+20 2 2480 07 03 / 04 | info@niconsulting.com.eg",
      Home: "home",
      AboutUs: "about us",
      JoinOurAcademy: "join our academy",
      Support: "help and support",
      TermsAndConditions: "terms and conditions",
      PrivacyPolicy: "privacy policy",
      SiteMap: "sitemap",
      ContactUs: "contact us",
      CopyRight: "NI Academy © 2021 Privacy Policy",
    },
    RESETPASS: {
      ResetPassword: "Reset Password",
      PleaseNewPassowrd: "Please enter your new passowrd",
      NewPassowrd: "Enter New Passowrd",
      NewPassowrdReq: "Password is required",
      NewPassowrdPtrn: "At least 6 characters: Lowercase letters & Uppercase letters & Numbers",
      ReEnterNewPassowrd: "Confirm New Passowrd",
      ReEnterNewPassowrdReq: "Confirm Password is required",
      DontMatch: "Password and Confirm Password do not match",
      ChangePasswordBTN: "Change Password",
    },
    REGISTER: {
      SignUpTo: "Sign up to",
      StartLearning: "Start Learning!",
      EmailRegistered: "This email has already been registered",
      FirstName: "First Name",
      FirstNameReq: "First Name is Required",
      LastName: "Last Name",
      LastNameReq: "Last Name is Required",
      Email: "Email",
      EmailReq: "Email is Required",
      EmailPtrn: "This Email is invalid",
      Password: "Password",
      NewPassowrdReq: "Password is required",
      NewPassowrdPtrn: "At least 6 characters: Lowercase letters & Uppercase letters & Numbers",
      allowsendemail: "Yes! I want to get the most out of NI Academy by receiving emails with exclusive deals, personal recommendations and learning tips!",
      SignUp: "Sign Up",
      Agree: "By signing up, you agree to our",
      TermsOfUse: "Terms of Use",
      And: "and",
      PrivacyPolicy: "Privacy Policy",
      AlreadyHaveAnAccount: "Already have an account",
    },
    LOGIN: {
      LoginTo: "login to your",
      NIAcademyAccount: "NI Academy Account",
      Error1: "The user is not allowed to use this system",
      Error2: "Please check the user name and the password again!",
      Email: "Email",
      EmailReq: "Email is Required",
      Password: "Password",
      PassowrdPtrn: "At least 6 characters: Lowercase letters & Uppercase letters & Numbers",
      PassowrdReq: "Password is Required",
      Login: "Login",
      ForgotPassword: "Forgot Password",
      CreateNewAccount: "Create new Account",
      Facebook: "Use your facebook account",
      Gmail: "Use your gmail account",
    },
    ForgetPass: {
      ForgotPassword: "Forgot Password",
      PleaseRegisteredEmail: "Please enter your registered email",
      Error1: "This user is no longer usable.",
      Error2: "Email not found.",
      Error3: "Mailer error Try at a later time",
      Success: "Sent, please check your email",
      Email: "Email",
      EmailReq1: "Email is required",
      EmailReq2: "This Email is invalid",
      ResetPassword: "Reset Password",
      AlreadyHaveAnAccount: "Already have an account",
      SignIn: "Sing In",
    },
    Error: {
      Message: "You don't have privileges,Please Contact NI Academy Administrator.",
      Home: "Back to Home",
    },
    ChangePass: {
      ChangePassword: "Change Password",
      OldPassword: "Old Password",
      OldPasswordReq: "Old Password Is Required",
      WrongPassword: "Wrong Password",
      NewPassword: "New Password",
      NewPasswordReq: "New Password Is Required",
      NewPasswordPtrn: "At least 6 characters: Lowercase letters & Uppercase letters & Numbers",
      ConfirmPassword: "Confirm Password",
      ConfirmPasswordReq1: "Confirm Password Is Required",
      ConfirmPasswordReq2: "Confirm Password Not Match With The New One",
      Save: "Save",
    },
    CourseDetails: {
      BuyNow: "Buy Now",
      CreatedBy:"created by:",
      Content: "courses content",
    },
    Benefits: {
      Title: "Current Training Programs",
      TitleBody: "NI Consulting Academy strides to provide the latest educational content that is beneficial to the trainee, all while enriching his or her abilities and professional skills in all fields. ",
      NIABenefits: "NI Academy Advantages",
      GradesAssignments: "Flexibility and Convenience",
      GradesAssignmentsBody: "Trainees can access and benefit from the training resources and programs at any time throughout the day",
      AttendanceBehavior: "The Education 2.0 Method",
      AttendanceBehaviorBody: "The success and effectiveness of the program depends on the eagerness of the trainee to learn and actively participate in the training.",
      DistrictStateTests: "Measurable Learning Progress",
      DistrictStateTestsBody: "The trainee will be able to follow up on his or her cognitive development through assessment results before and after attending the full course.",
      ACADEMICRESOURCES: "Direct Communication with the Trainer",
      ACADEMICRESOURCESBody: "The trainee attends an interactive lecture with the trainer, during which he or she can ask questions or discuss topics related to the course material .",
      ScheduleCourses: "Diversity of Topics",
      ScheduleCoursesBody: "Trainees can choose multiple programs, whether in different fields that enrich their knowledge range, or programs at higher levels in the same subject to be integrated and build a more comprehensive knowledge base for the trainee.",
      AfterSchoolSupport: "Knowledge Refreshment",
      AfterSchoolSupportBody: "The trainee can re-watch the course videos any number of times during the year following the end of the program to use the course and educational resources as a reference when needed.",
    },
    NewCourses: {
      NewCourses: "new courses",
    },
    RecentPrograms: {
      RecentPrograms: "recent programs",
      Body: "NI Academy has been providing top-quality, AMI Certified Montessori Education to Elementary, Primary, and Toddler aged children in the area.",
    },
    Topics: {
      TopicsRecommended: "topics recommended",
    },
    Course: {
      buynow: "buy now",
      EGP: "EGP",
      completed: "completed",
      NotTaken: "The final exam has not been taken yet",
      FinalScore: "Exam Score"
    },
    Search: {
      NotFound:"Sorry no result found match with your search",
      Back:"Back to Home",
      Explore:"Explore",
      Courses:"Courses",
      results:"results for ",
      Createdby:"Created by:",
    },
    Profile: {
      MyCourses: "my courses",
      MyInfo: "My Info",
      FirstName: "First Name",
      LastName: "Last Name",
      Email:"Mail"
    },
    Corporate: {
      CorporateManagement: "corporate management",
      AddNewCorporate: "Add New corporate",
      ContractNo: "Contract No.",
      ContractName: "Contract Name",
      ContactPerson: "Contact Person",
      NoOfCourses: "No. of Courses",
      NoOfGroups: "No. of Groups",
      NoOfTrainees: "No. of Trainees",

      CorporateInformation: "Corporate Information",
      CreateNewCorporate: "create new corporate",
      BackToCorporateList: "Back to Corporate List",
      Name: "Name",
      NamePH: "eg: NI Consulting",
      HeadOfficeAddress: "Head Office Address",
      HeadOfficeAddressPH: "eg: Star Capital A2 Office Building, 9th Floor, City Star Business and Commercial Complex 2, Aly Rashid St., Nasr City, Cairo, Egypt",
      LocationOnGoogleMaps: "Location on google Maps",
      LocationOnGoogleMapsPH: "eg: 30.07454134025531, 31.346966728836392",
      SaveCorporate: "Save Corporate",
      Return: "Return",

      Contracts: "Contracts",
      BasicInfromation: "Basic Infromation",
      Number: "Number",
      NumberPH: "eg: 20210428",
      StartDate: "Start Date",
      StartDatePH: "eg: 01/01/2021",
      EndDate: "End Date",
      EndDatePH: "eg: 31/01/2021",
      BIName: "Name",
      BINamePH: "eg: Contract Name",
      Next: "Next",
      ContactPersonInfromation: "Contact Person Infromation",
      CPIName: "Name",
      CPINamePH: "eg: Full Name",
      JobTitle: "Job Title",
      JobTitlePH: "eg: CEO",
      MobileNumber: "Mobile Number",
      MobileNumberPH: "eg: +20 XXX X XXX XXX",
      EmailAddress: "Email Address",
      EmailAddressPH: "eg: email@domain.com",
      Previous: "Previous",
      Course: "Course",
      SelectContractCourses: "Select Contract Courses",
      SaveContract: "Save Contract",
      SaveAndContinue: "Save and Continue",

      TraineeList: "Trainee List",
      TraineeName: "Trainee Name",
      TraineeNamePH: "eg: Full Name",
      TraineeEntity: "Trainee Entity",
      TraineeEntityPH: "eg: Ministry of Planning",
      SelectContractNumber: "Select Contract Number",
      AssignedCourses: "Assigned Courses",
      GroupName: "Group Name",
      GroupNumber: "Group Number/ID",
      Groups: "Groups",
      AddGroup: "Add Group",
      EditGroup: "Edit Group",
      SaveTrainee: "Save Trainee",
      Cancel: "Cancel",

      confirmationContractDelete: 'You Will Delete This Contract',
      confirmationTraineeDelete: 'You Will Delete This Trainee',
      confirmationGroupDelete: 'You Will Delete This Group',
      confirmationCorporateDelete: 'You Will Delete This Corporate',
      CorporateCreated: 'Corporate Created Successfully',
      CorporateUpdated: 'Corporate Updated Successfully',
      CorporateDeleted: 'Corporate Deleted Successfully',
      ContractCreated: 'Contract Created Successfully',
      ContractUpdated: 'Contract Updated Successfully',
      ContractDeleted: 'Contract Deleted Successfully',
      TraineeCreated: 'Trainee Created Successfully',
      TraineeUpdated: 'Trainee Updated Successfully',
      TraineeDeleted: 'Trainee Deleted Successfully',

      NotUniqueCorporate: 'This corporate is already exist',
      NotUniqueContract: 'This contract is already exist',
      NotUniqueTrainee: 'This trainee is already exist',

      ContractHavDependance: 'This contract has dependency delete them first',
      TraineeHavDependance: 'This trainee has dependency delete them first',
      ExportToExcel: 'Export To Excel',
      QuizResults: 'Quiz Results',
      Before: 'Before',
      After: 'After',
      Complete: 'Complete',
      ResetResults: 'Reset The Final Result'
    },
    CreateCourse: {
      CourseInformation: 'Course Information',
      CreateCourse:'Create New Course',
      CourseVedios:'Course Vedios',
      Price:'Price',
      CourseNameEnglish:'Course Name in English Language',
      CourseDetialsEnglish:'Course Details in English Language',
      CourseNameArabic:'Course Name in Arabic Language',
      CourseDetailsArabic: 'Course Details in Arabic Language',
      UploadCourse: 'Upload Course Image',
      ChooseCategory:'Choose Category',
      ChooseFile:'Choose File',
      SaveandContinue:'Save and Continue',
      Cancel:'Cancel',
      SaveandFinish: 'Save and Finish',
      BankofQuestions: 'Bank of Questions',
      VideoName: 'Video Name by English',
      VideoArName: 'Video Name by Arabic',
      VideoThumbnail: 'Video Thumbnail',
      Add: 'Add',
      NameEn: 'Name [EN]',
      NameAr: 'Name [AR]',
      Thumbnail: 'Thumbnail',
      Path: 'Path',
      TheQuestion: 'The Question',
      VideoLink: 'Enter video link on Server',
      Answer01: 'Answer 01',
      Answer02: 'Answer 02',
      Answer03: 'Answer 03',
      Answer04: 'Answer 04',
      RightAnswer: 'Right Answer',
      ConfirmAnswer: 'Do you want to save and continue?'

    },
  }
};
