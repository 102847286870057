import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { LanguageService } from '../../../shared/services/Language.service';
import { Subscription } from 'rxjs';
import { UserDto } from '../../../shared/core/system-api';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-Forgotpassword',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  public ForgotpasswordForm: FormGroup;
  public loading = false;
  public submitted = false;
  public WrongUser = false;
  public isEmailSent = false;
  public Error = false;
  public InActiveUser = false;
  currentlang: string;

  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/


  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private languageService: LanguageService,
    private toastr: ToastrService
  ) {
    const currentLangSubscription = this.languageService.currentLang.subscribe(res => { this.currentlang = res; });
  }

  ngOnInit() {
    this.ForgotpasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  public get f() { return this.ForgotpasswordForm.controls; }

  onSubmit() {

    this.submitted = true;
    this.loading = true;
    this.isEmailSent = false;

    if (this.ForgotpasswordForm.invalid) {
      this.loading = false;
      return;
    }

    var email = this.f.email.value;
    const ForgotpasswordSubscription = this.authenticationService.forgotPassword(email)
      .subscribe(
        res => {

          this.loading = false;
          if (res.success) {
            let user = <UserDto>res.data;
            if (!user.isActive) {
              this.InActiveUser = true;
              this.WrongUser = false;
              this.isEmailSent = false;
              this.Error = false;
            }
            else if (user.isSocialLogin) {
              this.InActiveUser = false;
              this.WrongUser = true;
              this.isEmailSent = false;
              this.Error = false;
            }
            else {
              this.isEmailSent = true;
              this.WrongUser = false;
              this.InActiveUser = false;
              this.Error = false;
            }
          }
          else if (res.code == 'UserNotFound') {
            this.WrongUser = true;
            this.InActiveUser = false;
            this.isEmailSent = false;
            this.Error = false;
          }
          else if (res.code == 'EmailNotDelivered') {
            this.WrongUser = false;
            this.InActiveUser = false;
            this.isEmailSent = false;
            this.Error = true;
          }
        },
        error => {
          if (this.currentlang == "us")
            this.toastr.error("Something went wrong. Please contact the IT department.");
          else
            this.toastr.error("حدث خطأ ما. يرجى التواصل مع قسم تقنية المعلومات");
          this.loading = false;
        });

    this.unsubscribe.push(ForgotpasswordSubscription);
  }
  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }
}
