export class corporateDetails {
  contractName: string;
  contractNumber: string;
  courseName: string;
  traineeName: string;
  traineeEmail: string;
  traineeQuizResultBefore: string;
  traineeQuizResultAfter: string;
  traineeComplete: string;
  constructor() {
    this.contractName = '';
    this.contractNumber = '';
    this.courseName = '';
    this.traineeName = '';
    this.traineeEmail = '';
    this.traineeQuizResultBefore = '';
    this.traineeQuizResultAfter = '';
    this.traineeComplete = '';
  }
}
