import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {CourseService} from '../../../../shared/services/course.service';

@Component({
  selector: 'app-courses-list',
  templateUrl: './courses-list.component.html',
  styleUrls: ['./courses-list.component.scss']
})
export class CoursesListComponent implements OnInit {

  courses_list: any[] = [];

  constructor(private courseService: CourseService,
             private router: Router, ) { }

  ngOnInit() {
    this.courseService.getCoursesList().subscribe((res:any)=>{
      this.courses_list = res.data;
      console.log("The Coures list :----", this.courses_list)
    })


  }

  NavigateToEditCourse(item){
    this.router.navigate(['/AdminSite/EditCourse'], { queryParams: { Course: item } });

    

  }

}
