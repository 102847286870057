import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserDto } from '../../../shared/core/system-api';
import { LanguageService } from '../../../shared/services/Language.service';
import { UserCourseService } from '../../../shared/services/userCourse.service';

@Component({
  selector: 'app-student-profile',
  templateUrl: './student-profile.component.html',
  styleUrls: ['./student-profile.component.scss']
})
export class StudentProfileComponent implements OnInit {
  items: any[] = [];
    currentUserSubject: any;
  currentUser: any;

  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  currentlang: string;
  constructor(private languageService: LanguageService,private userCourseService: UserCourseService) {
    this.currentUserSubject = new BehaviorSubject<UserDto>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    const currentLangSubscription = this.languageService.currentLang.subscribe(res => { this.currentlang = res; });
  }

  ngOnInit(): void {
    this.userCourseService.getUserCoursesList().subscribe(result => {
      if (result.success) {
        this.items = result.data;
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }

}
