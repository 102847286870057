import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CategoryService } from '../../../shared/services/category.service';
import { ActivatedRoute } from '@angular/router';
import { Category } from '../../../shared/core/system-api';

@Component({
  selector: 'app-topic',
  templateUrl: './topic.component.html',
  styleUrls: ['./topic.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class TopicComponent implements OnInit {

  items: any[] = [];
  CategoryID: number = 0;
  category: Category;
  constructor(private route: ActivatedRoute, private categoryService: CategoryService)
  {
   
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.CategoryID = Number(params.get('categoryID'));

      if (this.CategoryID && this.CategoryID != 0)
      {
        this.categoryService.getCategoryCourses(this.CategoryID).subscribe(result => {
          if (result.success) {
            this.items = result.data;
          }
        });

        this.categoryService.get(this.CategoryID).subscribe(result => {
          if (result.success) {
            this.category = <Category>result.data;
          }
        });
      }
 
    });
  }

}
