import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ForgetPasswordComponent } from "./forget-password/forget-password.component";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { ResetPasswordComponent } from "./ResetPassword/ResetPassword.component";
 
const routes: Routes = [
  { path: 'SignIn', component: LoginComponent },
  { path: 'SignUp', component: RegisterComponent },
  { path: 'ResetPassword', component: ResetPasswordComponent },
  { path: 'ForgetPassword', component: ForgetPasswordComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class AuthrizeRoutingModule {

}
